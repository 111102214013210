import React, { useState, useEffect } from "react";
import { Collapse } from "antd";
import ReactJson from "react-json-view";
const { Panel } = Collapse;
const ConfigDetail = (props) => {
  const [editObject, setEditObject] = useState(props.configData);

  useEffect(() => {
    setEditObject(props.configData);
  }, [props.configData]);

  return (
    <div>
      {editObject.length > 0 ? (
        <Collapse defaultActiveKey={["0"]}>
          {editObject.map((content, i) => {
            return (
              <Panel header={content.config_details} key={i}>
                <ReactJson
                  src={content}
                  collapsed="3"
                  displayObjectSize="false"
                  displayDataTypes="false"
                  name={content.config_details}
                />
              </Panel>
            );
          })}
        </Collapse>
      ) : (
        "Loading...."
      )}
    </div>
  );
};

export default ConfigDetail;
