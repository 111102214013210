import React, { useState, useEffect } from "react";
import {
  MoreOutlined,
  UpOutlined,
  DownOutlined,
  FieldTimeOutlined,
  CalendarOutlined,
  InteractionOutlined,
} from "@ant-design/icons";
import {
  Form,
  Button,
  Select,
  Drawer,
  Checkbox,
  Row,
  Col,
  InputNumber,
  Input,
  Collapse,
  Tabs,
  Table,
  Popover,
  notification,
} from "antd";

import anychart from "anychart";
import axios from "axios";
import api from "../utils/api";
import moment from "moment";
import Sidebar from "../components/Sidebar";
const { Panel } = Collapse;

const valuesDefault = {
  yearFrom: moment().subtract(0, "year").format("YYYY"),
  monthFrom: moment().subtract(2, "months").format("MM"),
  dayFrom: moment().format("DD"),
  yearTo: moment().year(),
  monthTo: moment().subtract(0, "months").format("MM"),
  dayTo: moment().format("DD"),
  currencies: "EUR_USD",
  //currencies: "AUD_USD",
  timeframe: "M60",
  REI: 8,
  DEMARKER: 13,
  dataset: "train",
  model: "CNN_NEW_TDC",
  annotations: ["extremePoints", "trends"],
  TIMEPERIOD_SLOW_MA: 21,
  TIMEPERIOD_MEDIUM_MA: 13,
  TIMEPERIOD_FAST_MA: 5,
  TIMEPERIOD_SLOW_EMA: 14,
  TIMEPERIOD_MEDIUM_EMA: 10,
  TIMEPERIOD_FAST_EMA: 5,
  TIMEPERIOD_BBANDS: 14,
  TIMEFRAME_TO_CANDLE_PATTERN_TEST: 0.45,
  size5Mins: 10,
  size10Mins: 10,
  size15Mins: 13,
  size30Mins: 18,
  size60Mins: 28,
  size240Mins: 38,
  PEAK_X5Mins: 15,
  PEAK_X10Mins: 15,
  PEAK_X15Mins: 15,
  PEAK_X30Mins: 15,
  PEAK_X60Mins: 15,
  PEAK_X240Mins: 15,
  LEVEL_WINDOW5Mins: 300,
  LEVEL_WINDOW10Mins: 300,
  LEVEL_WINDOW15Mins: 300,
  LEVEL_WINDOW30Mins: 300,
  LEVEL_WINDOW60Mins: 300,
  LEVEL_WINDOW240Mins: 300,
  LEVEL_RANGE5Mins: 3,
  LEVEL_RANGE10Mins: 5,
  LEVEL_RANGE15Mins: 5,
  LEVEL_RANGE30Mins: 10,
  LEVEL_RANGE60Mins: 10,
  LEVEL_RANGE240Mins: 15,
  trend5Mins: 3,
  trend10Mins: 5,
  trend15Mins: 5,
  trend30Mins: 10,
  trend60Mins: 10,
  trend240Mins: 15,
  FLAGS_FLAGS5Mins: 15,
  FLAGS_FLAGS10Mins: 15,
  FLAGS_FLAGS15Mins: 15,
  FLAGS_FLAGS30Mins: 20,
  FLAGS_FLAGS60Mins: 20,
  FLAGS_FLAGS240Mins: 20,
  tolerance_pips: 1,
  min_level_hits: 5,
  flag_height: 25,
  sizeOneD: 48,
  PEAK_XOneD: 15,
  WINDOWOneD: 300,
  RANGEOneD: 20,
  trendOneD: 20,
  FLAGSOneD: 20,
};

const valuesDefaultAnalysis = {
  currencies: ["USD_JPY"],
  timeframe: "M240",
  sell_success_gain_pips: -50,
  sell_failure_gain_pips: 20,
  buy_success_gain_pips: 50,
  buy_failure_gain_pips: -20,
  num_candles: 4,
  tolerance_level: 5,
  type: "Medium",
  before_candles: 10,
  after_candles: 10,
};
const Indicators = () => {
  const [loader, setLoader] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [isStatistics, setIsStatistics] = useState(false);
  const [plotGlobal, setPlotGlobal] = useState(null);
  const [chartGlobals, setChartGlobals] = useState(null);
  const [submitedData, setSubmitedData] = useState(null);
  const [uptrendCount, setUptrendCount] = useState(0);
  const [downtrendCount, setdowntrendCount] = useState(0);
  const [flagsCount, setFlagsCount] = useState(0);
  const [hammerCount, setHammerCount] = useState(0);
  const [invertedCount, setInvertedHammerCount] = useState(0);
  const [highsCount, setHighsCount] = useState(0);
  const [lowsCount, setLowsCount] = useState(0);
  const [supportCount, setSupportCount] = useState(0);
  const [resistanceCount, setResistanceCount] = useState(0);
  const [dateSearched, setDateSearched] = useState(false);
  const [rowActive, setRowActive] = useState(null);
  const [detailReport, setDetailReport] = useState(false);
  const [dataAnalysis, setDataAnalysis] = useState(null);
  const [path, setPath] = useState(null);
  const [recordSummary, setRecordSummary] = useState(null);
  const [applyMovingAvg, setApplyMovingAvg] = useState(false);

  const [form] = Form.useForm();
  const currencies = Form.useWatch("currencies", form);
  const timeframe = Form.useWatch("timeframe", form);
  const REI = Form.useWatch("REI", form);
  const DEMARKER = Form.useWatch("DEMARKER", form);
  const yearFrom = Form.useWatch("yearFrom", form);
  const monthFrom = Form.useWatch("monthFrom", form);
  const dayFrom = Form.useWatch("dayFrom", form);
  const yearTo = Form.useWatch("yearTo", form);
  const monthTo = Form.useWatch("monthTo", form);
  const dayTo = Form.useWatch("dayTo", form);

  const filterAnotation = [
    { label: "Candlestick Patterns", value: "H/I" },
    { label: "Extreme Points", value: "extremePoints" },
    { label: "Levels", value: "levels" },
    { label: "Trends", value: "trends" },
    { label: "Flags", value: "flags" },
  ];

  const { Option } = Select;

  const showFilter = () => {
    setOpenFilter(true);
  };
  const onCloseFilter = () => {
    setOpenFilter(false);
  };
  const toggleStatistic = () => {
    setIsStatistics(!isStatistics);
  };

  useEffect(() => {
    onFinish();
    document.title = "Indicators";
  }, []);

  const onFinish = (values) => {
    let annotationsArray = [];

    setLoader(true);
    let formData = {};
    if (values) {
      for (const element of values.annotations) {
        if (element === "H/I") {
          annotationsArray.push("H/I");
        }
        if (element === "extremePoints") {
          annotationsArray.push("Highs", "Lows");
        }
        if (element === "levels") {
          annotationsArray.push("Support", "Resistance");
        }
        if (element === "trends") {
          annotationsArray.push("HighsTrends", "LowsTrends");
        }
        if (element === "flags") {
          annotationsArray.push("Flags");
        }
      }
      formData = {
        from: `${yearFrom}-${monthFrom}-${dayFrom}`,
        to: `${yearTo}-${monthTo}-${dayTo} 23:59:59`,
        currency: currencies,
        dataset: "train",
        model: "CNN_NEW_TDC",
        timeframe: timeframe,
        REI: REI,
        DEMARKER: DEMARKER,
        annotations: annotationsArray,
        TIMEPERIOD_SLOW_MA: values.TIMEPERIOD_SLOW_MA || 21,
        TIMEPERIOD_MEDIUM_MA: values.TIMEPERIOD_MEDIUM_MA || 13,
        TIMEPERIOD_FAST_MA: values.TIMEPERIOD_FAST_MA || 5,

        TIMEPERIOD_SLOW_EMA: values.TIMEPERIOD_SLOW_EMA || 14,
        TIMEPERIOD_MEDIUM_EMA: values.TIMEPERIOD_MEDIUM_EMA || 10,
        TIMEPERIOD_FAST_EMA: values.TIMEPERIOD_FAST_EMA || 5,

        TIMEPERIOD_BBANDS: values.TIMEPERIOD_BBANDS || 14,
        TIMEFRAME_TO_CANDLE_PATTERN_TEST:
          values.TIMEFRAME_TO_CANDLE_PATTERN_TEST || 0.45,
        TIMEFRAME_TO_CANDLE_PATTERN_SIZE_TEST: {
          "5Min": values.size5Mins || 10,
          "10Min": values.size10Mins || 10,
          "15Min": values.size15Mins || 13,
          "30Min": values.size30Mins || 18,
          "60Min": values.size60Mins || 28,
          "240Min": values.size240Mins || 38,
          "1D": values.sizeOneD || 48,
        },
        TIMEFRAME_TO_CANDLE_PEAK_X: {
          "5Min": values.PEAK_X5Mins || 15,
          "10Min": values.PEAK_X10Mins || 15,
          "15Min": values.PEAK_X15Mins || 15,
          "30Min": values.PEAK_X30Mins || 15,
          "60Min": values.PEAK_X60Mins || 15,
          "240Min": values.PEAK_X240Mins || 15,
          "1D": values.PEAK_XOneD || 15,
        },
        TIMEFRAME_TO_CANDLE_PEAK_LEVEL_WINDOW: {
          "5Min": values.LEVEL_WINDOW5Mins || 300,
          "10Min": values.LEVEL_WINDOW10Mins || 300,
          "15Min": values.LEVEL_WINDOW15Mins || 300,
          "30Min": values.LEVEL_WINDOW30Mins || 300,
          "60Min": values.LEVEL_WINDOW60Mins || 300,
          "240Min": values.LEVEL_WINDOW240Mins || 300,
          "1D": values.WINDOWOneD || 300,
        },
        TIMEFRAME_TO_CANDLE_PEAK_LEVEL_RANGE: {
          "5Min": values.LEVEL_RANGE5Mins || 3,
          "10Min": values.LEVEL_RANGE10Mins || 5,
          "15Min": values.LEVEL_RANGE15Mins || 5,
          "30Min": values.LEVEL_RANGE30Mins || 10,
          "60Min": values.LEVEL_RANGE60Mins || 10,
          "240Min": values.LEVEL_RANGE240Mins || 15,
          "1D": values.RANGEOneD || 20,
        },
        TIMEFRAME_TO_TREND_LINE_TOLERANCE: {
          "5Min": values.trend5Mins || 3,
          "10Min": values.trend10Mins || 5,
          "15Min": values.trend15Mins || 5,
          "30Min": values.trend30Mins || 10,
          "60Min": values.trend60Mins || 10,
          "240Min": values.trend240Mins || 15,
          "1D": values.trendOneD || 20,
        },
        TIMEFRAME_TO_CANDLE_PEAK_LEVEL_FLAGS: {
          "5Min": values.FLAGS_FLAGS5Mins || 15,
          "10Min": values.FLAGS_FLAGS10Mins || 15,
          "15Min": values.FLAGS_FLAGS15Mins || 15,
          "30Min": values.FLAGS_FLAGS30Mins || 20,
          "60Min": values.FLAGS_FLAGS60Mins || 20,
          "1D": values.FLAGSOneD || 20,
          "240Min": values.FLAGS_WINDOW240Mins || 20,
          tolerance_pips: values.tolerance_pips || 1,
          min_level_hits: values.min_level_hits || 5,
          flag_height: values.flag_height || 25,
        },
      };
    } else {
      if (submitedData === null) {
        formData = {
          from: `${valuesDefault.yearFrom}-${valuesDefault.monthFrom}-${valuesDefault.dayFrom}`,
          to: `${valuesDefault.yearTo}-${valuesDefault.monthTo}-${valuesDefault.dayTo} 23:59:59`,
          currency: valuesDefault.currencies,
          dataset: "train",
          model: "CNN_NEW_TDC",
          timeframe: valuesDefault.timeframe,
          REI: valuesDefault.REI,
          DEMARKER: valuesDefault.DEMARKER,
          annotations: ["Highs", "Lows", "HighsTrends", "LowsTrends"],
          TIMEPERIOD_SLOW_MA: 21,
          TIMEPERIOD_MEDIUM_MA: 13,
          TIMEPERIOD_FAST_MA: 5,

          TIMEPERIOD_SLOW_EMA: 14,
          TIMEPERIOD_MEDIUM_EMA: 10,
          TIMEPERIOD_FAST_EMA: 5,

          TIMEPERIOD_BBANDS: 14,
          TIMEFRAME_TO_CANDLE_PATTERN_TEST: 0.45,
          TIMEFRAME_TO_CANDLE_PATTERN_SIZE_TEST: {
            "5Min": 10,
            "10Min": 10,
            "15Min": 13,
            "30Min": 18,
            "60Min": 28,
            "240Min": 38,
            "1D": 48,
          },
          TIMEFRAME_TO_CANDLE_PEAK_X: {
            "5Min": 15,
            "10Min": 15,
            "15Min": 15,
            "30Min": 15,
            "60Min": 15,
            "240Min": 15,
            "1D": 15,
          },
          TIMEFRAME_TO_CANDLE_PEAK_LEVEL_WINDOW: {
            "5Min": 300,
            "10Min": 300,
            "15Min": 300,
            "30Min": 300,
            "60Min": 300,
            "240Min": 300,
            "1D": 300,
          },
          TIMEFRAME_TO_CANDLE_PEAK_LEVEL_RANGE: {
            "5Min": 3,
            "10Min": 5,
            "15Min": 5,
            "30Min": 10,
            "60Min": 10,
            "240Min": 15,
            "1D": 20,
          },
          TIMEFRAME_TO_TREND_LINE_TOLERANCE: {
            "5Min": 3,
            "10Min": 5,
            "15Min": 5,
            "30Min": 10,
            "60Min": 10,
            "240Min": 15,
            "1D": 20,
          },
          TIMEFRAME_TO_CANDLE_PEAK_LEVEL_FLAGS: {
            "5Min": 15,
            "10Min": 15,
            "15Min": 15,
            "30Min": 20,
            "60Min": 20,
            "240Min": 20,
            "1D": 20,
            tolerance_pips: 1,
            min_level_hits: 5,
            flag_height: 25,
          },
        };
      } else {
        formData = submitedData;
        setSubmitedData(null);
      }
    }
    setSubmitedData(formData);

    api({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_CURRENCY_STRENGTH}`,
      data: formData,
    })
      .then((res) => {
        //============== Chart Data ==================
        const currencyKeyArray = Object.keys(res.data.data);
        const currencyKey = currencyKeyArray[0];
        let result = res.data.data[currencyKey];
        setHighsCount(0);
        setLowsCount(0);
        setUptrendCount(0);
        setdowntrendCount(0);
        setFlagsCount(0);
        setResistanceCount(0);
        setSupportCount(0);
        setHammerCount(0);
        setInvertedHammerCount(0);
        setRowActive(null);

        // console.log("check", chartData);
        //==============  Resistance Data ==================
        let resistance = [];
        if (res.data.resistance) {
          const resistanceKeyArray = Object.keys(res.data.resistance);
          const resistanceKey = resistanceKeyArray[0];
          resistance = res.data.resistance[resistanceKey];
          setResistanceCount(resistance.length);
        }

        //==============  Support Data ==================
        let support = [];
        if (res.data.support) {
          const supportKeyArray = Object.keys(res.data.support);
          const supportKey = supportKeyArray[0];
          support = res.data.support[supportKey];
          setSupportCount(support.length);
        }

        let up_trend = [];
        if (res.data.up_trend) {
          const upTrendKeyArray = Object.keys(res.data.up_trend);

          const upTrendKey = upTrendKeyArray[0];
          up_trend = res.data.up_trend[upTrendKey];
          setUptrendCount(up_trend.length);
        }
        let down_trend = [];
        if (res.data.down_trend) {
          const downTrendKeyArray = Object.keys(res.data.down_trend);
          const downTrendKey = downTrendKeyArray[0];
          down_trend = res.data.down_trend[downTrendKey];
          setdowntrendCount(down_trend.length);
        }

        let flags = [];
        if (res.data.flags) {
          flags = res.data.flags;
          setFlagsCount(flags.length);
        }

        //  console.log("rr", resistance);
        // ============= Chart Configuration ==========
        let offset = new Date().getTimezoneOffset();
        anychart.format.outputTimezone(offset);

        let dataTable = anychart.data.table();

        let simpleHammer = [];
        let invertedHammer = [];
        let chart = anychart.stock();
        let plot = chart.plot(0);
        setPlotGlobal(plot);
        let annotationPeaks = plot.annotations();
        let controller = plot.annotations();

        chart.scroller().xAxis(false);

        // ============= Chart Configuration ==========

        for (const element of result) {
          let data = element;

          let candleData = [
            data["datetime"],
            data["OPEN"],
            data["HIGH"],
            data["LOW"],
            data["CLOSE"],
            data["REI"],
            data["DEMARKER"],
            data["ATR"],
            data["RSI"],
            data["ADX"],
            data["MINUS_DI"],
            data["PLUS_DI"],
            data["STOCH_SLOWD"],
            data["STOCH_SLOWK"],
            data["MA_FAST"],
            data["MA_SLOW"],
            data["MA_MEDIUM"],

            data["BBANDS_LOWER"],
            data["BBANDS_MIDDLE"],
            data["BBANDS_UPPER"],
            data["PEAK"],
            data["BOTTOM"],
            data["CANDLE_LABEL"],
            data["COUNT"],
            data["HAMMER"],
            data["INVERTED_HAMMER"],
            data["LABEL"],
            data["ADX_LABEL"],
            data["RSI_LABEL"],
            data["PEAK_TREND_DISTANCE"] !== "None"
              ? data["PEAK_TREND_DISTANCE"]
              : 0,
            data["BOTTOM_TREND_DISTANCE"] !== "None"
              ? data["BOTTOM_TREND_DISTANCE"]
              : 0,
            data["CANDLE_NUM"],
            data["EMA_FAST"],
            data["EMA_SLOW"],
            data["EMA_MEDIUM"],
          ];

          dataTable.addData([candleData]);

          if (data["PEAK"] > 0) {
            setHighsCount((highsCount) => highsCount + 1);
            annotationPeaks
              .marker()
              .xAnchor(data["datetime"])
              .valueAnchor(data["HIGH"])
              .fill("#000")
              .stroke("1 #000 0.75")
              .markerType("circle")
              .size(2)
              .offsetY(-10);
            // .allowEdit(false);
          } else if (data["BOTTOM"] > 0) {
            setLowsCount((lowsCount) => lowsCount + 1);
            annotationPeaks
              .marker()
              .xAnchor(data["datetime"])
              .valueAnchor(data["LOW"])
              .fill("#000")
              .stroke("1 #000 0.75")
              .markerType("circle")
              .size(2)
              .offsetY(10);
          }

          if (data["HAMMER"] === "100") {
            setHammerCount((hammerCount) => hammerCount + 1);
            const hammerData = {
              date: data["datetime"],
              description: "HAMMER " + data["datetime"],
            };
            simpleHammer.push(hammerData);
          } else if (data["INVERTED_HAMMER"] === "100") {
            setInvertedHammerCount((invertedCount) => invertedCount + 1);
            const hammerData = {
              date: data["datetime"],
              description: "INVERTED_HAMMER " + data["datetime"],
            };
            invertedHammer.push(hammerData);
          }
        }

        //==================== Resistance & Support ======================
        if (resistance.length > 0) {
          for (const element of resistance) {
            let rStartValue = element["RESISTANCE_START_VALUE"];
            let rEndValue = element["RESISTANCE_END_VALUE"];
            controller
              .line({
                xAnchor: element["RESISTANCE_START"],
                valueAnchor: rStartValue,
                secondXAnchor: element["RESISTANCE_END"],
                secondValueAnchor: rEndValue,
                normal: { stroke: "1 green" },
              })
              .allowEdit(false);
          }
        }
        if (support.length > 0) {
          for (const element of support) {
            let sStartValue = element["SUPPORT_START_VALUE"];
            let sEndValue = element["SUPPORT_END_VALUE"];
            controller
              .line({
                xAnchor: element["SUPPORT_START"],
                valueAnchor: sStartValue,
                secondXAnchor: element["SUPPORT_END"],
                secondValueAnchor: sEndValue,
                normal: { stroke: "1 #1890ff" },
              })
              .allowEdit(false);
          }
        }
        //==================== Resistance & Support ======================

        //==================== Trends ======================
        if (up_trend.length > 0) {
          for (const element of up_trend) {
            let data = element;
            controller
              .line({
                xAnchor: data["PEAK_TREND_START"],
                valueAnchor: data["PEAK_TREND_START_VALUE"],
                secondXAnchor: data["PEAK_TREND_END"],
                secondValueAnchor: data["PEAK_TREND_END_VALUE"],
              })
              .allowEdit(false)
              .stroke({ color: "green" });

            controller
              .line({
                xAnchor: data["PEAK_TREND_END"],
                valueAnchor: data["PEAK_TREND_END_VALUE"],
                secondXAnchor: data["PEAK_TREND_EXTENDED"],
                secondValueAnchor: data["PEAK_TREND_EXTENDED_VALUE"],
              })
              .allowEdit(false)
              .stroke({ color: "green", dash: "4 3" });
          }
        }
        if (down_trend.length > 0) {
          for (const element of down_trend) {
            let data = element;
            controller
              .line({
                xAnchor: data["BOTTOM_TREND_START"],
                valueAnchor: data["BOTTOM_TREND_START_VALUE"],
                secondXAnchor: data["BOTTOM_TREND_END"],
                secondValueAnchor: data["BOTTOM_TREND_END_VALUE"],
              })
              .allowEdit(false)
              .stroke({ color: "red" });

            controller
              .line({
                xAnchor: data["BOTTOM_TREND_END"],
                valueAnchor: data["BOTTOM_TREND_END_VALUE"],
                secondXAnchor: data["BOTTOM_TREND_EXTENDED"],
                secondValueAnchor: data["BOTTOM_TREND_EXTENDED_VALUE"],
              })
              .allowEdit(false)
              .stroke({ color: "red", dash: "4 3" });
          }
        }
        //==================== Trends ======================

        if (flags.length > 0) {
          for (const element of flags) {
            let data = element;
            controller
              .line({
                xAnchor: data.resistance["start"],
                valueAnchor: data.resistance.prices[0],
                secondXAnchor: data.resistance["end"],
                secondValueAnchor: data.resistance.prices[1],
              })
              .allowEdit(false)
              .stroke({ color: "#000", dash: "4 3" });

            controller
              .line({
                xAnchor: data.support["start"],
                valueAnchor: data.support.prices[0],
                secondXAnchor: data.support["end"],
                secondValueAnchor: data.support.prices[1],
              })
              .allowEdit(false)
              .stroke({ color: "#000", dash: "4 3" });
          }
        }

        plot.eventMarkers({
          groups: [
            {
              format: " ",
              data: simpleHammer,
              normal: {
                type: "circle",
                fill: "#000",
                width: 8,
                height: 8,
              },
            },
            {
              format: " ",
              data: invertedHammer,
              normal: {
                type: "circle",
                fill: "#ffc40c",
                width: 8,
                height: 8,
              },
            },
          ],
        });

        let mapping = dataTable.mapAs();
        mapping.addField("datetime", 0, "datetime");
        mapping.addField("open", 1, "open");
        mapping.addField("high", 2, "high");
        mapping.addField("low", 3, "low");
        mapping.addField("close", 4, "close");
        mapping.addField("REI", 5, "REI");
        mapping.addField("DEMARKER", 6, "DEMARKER");
        mapping.addField("ATR", 7, "ATR");
        mapping.addField("RSI", 8, "RSI");
        mapping.addField("ADX", 9, "ADX");
        mapping.addField("MINUS_DI", 10, "MINUS_DI");
        mapping.addField("PLUS_DI", 11, "PLUS_DI");
        mapping.addField("STOCH_SLOWD", 12, "STOCH_SLOWD");
        mapping.addField("STOCH_SLOWK", 13, "STOCH_SLOWK");
        mapping.addField("MA_FAST", 14, "MA_FAST");
        mapping.addField("MA_SLOW", 15, "MA_SLOW");
        mapping.addField("MA_MEDIUM", 16, "MA_MEDIUM");
        mapping.addField("BBANDS_LOWER", 17, "BBANDS_LOWER");
        mapping.addField("BBANDS_MIDDLE", 18, "BBANDS_MIDDLE");
        mapping.addField("BBANDS_UPPER", 19, "BBANDS_UPPER");
        mapping.addField("PEAK", 20, "PEAK");
        mapping.addField("BOTTOM", 21, "BOTTOM");
        mapping.addField("CANDLE_LABEL", 22, "CANDLE_LABEL");
        mapping.addField("COUNT", 23, "COUNT");
        mapping.addField("HAMMER", 24, "HAMMER");
        mapping.addField("INVERTED_HAMMER", 25, "INVERTED_HAMMER");
        mapping.addField("LABEL", 26, "LABEL");
        mapping.addField("ADX_LABEL", 27, "ADX_LABEL");
        mapping.addField("RSI_LABEL", 28, "RSI_LABEL");
        mapping.addField("PEAK_TREND_DISTANCE", 29, "PEAK_TREND_DISTANCE");
        mapping.addField("BOTTOM_TREND_DISTANCE", 30, "BOTTOM_TREND_DISTANCE");
        mapping.addField("CANDLE_NUM", 31, "CANDLE_NUM");
        mapping.addField("EMA_FAST", 32, "EMA_FAST");
        mapping.addField("EMA_SLOW", 33, "EMA_SLOW");
        mapping.addField("EMA_MEDIUM", 34, "EMA_MEDIUM");

        let serires = plot.candlestick(mapping);

        serires.legendItem(false);

        let tooltip = chart.tooltip();

        tooltip.background().fill("#2c2c2c");
        tooltip.background().stroke("#2c2c2c");
        tooltip.fontColor("#fff");

        // create line series with mapping
        let bottomLines = chart.plot(1);
        plot.height("75%");
        bottomLines.height("25%");
        let margin = chart.margin();
        margin.top(0);
        margin.right("20px");
        margin.bottom(0);
        margin.left("20px");

        chart.splitters().normal().stroke({
          color: "red",
          dash: "3 4",
          thickness: 2,
          opacity: 0.9,
        });
        chart.splitters().hovered().stroke({
          color: "blue",
          dash: "3 4",
          thickness: 2,
          opacity: 0.9,
        });
        chart.splitters().preview().fill({
          color: "green",
          opacity: 0.5,
        });
        plot.legend().itemsFormat(function () {
          return this.seriesName;
        });

        chart
          .plot(1)
          .legend()
          .itemsFormat(function () {
            return this.seriesName;
          });
        plot.legend().title(false);
        chart.plot(1).legend().title(false);
        let REIplot = dataTable.mapAs({ value: 5 });
        let REIline = chart.plot(1).line(REIplot);
        REIline.name("REI");
        REIline.stroke("#00ab5b 0.9");
        REIline.enabled(false);

        let demarkerplot = dataTable.mapAs({ value: 6 });
        let demarkerline = chart.plot(1).line(demarkerplot);
        demarkerline.name("DEMARKER");
        demarkerline.stroke("#990000 0.9");
        demarkerline.enabled(false);

        let atrplot = dataTable.mapAs({ value: 7 });
        let atrline = chart.plot(1).line(atrplot);
        atrline.name("ATR");
        atrline.stroke("#013179 0.9");
        atrline.enabled(false);

        let rsi = dataTable.mapAs({ value: 8 });
        let rsiline = chart.plot(1).line(rsi);
        rsiline.name("RSI");
        rsiline.stroke("#ad6bd3 0.9");
        rsiline.enabled(false);

        let adxplot = dataTable.mapAs({ value: 9 });
        let adxline = chart.plot(1).line(adxplot);
        adxline.name("ADX");
        adxline.stroke("#1ed760 0.9");
        adxline.enabled(false);

        let minusDIPLot = dataTable.mapAs({ value: 10 });
        let minusDIline = chart.plot(1).line(minusDIPLot);
        minusDIline.name("MINUS_DI");
        minusDIline.stroke("#ffd97c 0.9");
        minusDIline.enabled(false);

        let plusDIPLot = dataTable.mapAs({ value: 11 });
        let plusDIline = chart.plot(1).line(plusDIPLot);
        plusDIline.name("PLUS_DI");
        plusDIline.stroke("red 0.9");
        plusDIline.enabled(false);

        let stochplot = dataTable.mapAs({ value: 12 });
        let stochplotline = chart.plot(1).line(stochplot);
        stochplotline.name("STOCH_SLOWD");
        stochplotline.stroke("#3d5954 0.9");
        stochplotline.enabled(false);

        let stochslowkplot = dataTable.mapAs({ value: 13 });
        let stochslowline = chart.plot(1).line(stochslowkplot);
        stochslowline.name("STOCH_SLOWK");
        stochslowline.stroke("#fcafac 0.9");
        stochslowline.enabled(false);

        plot
          .line(dataTable.mapAs({ value: 14 }))
          .stroke("1 #4FFF33")
          .name("MA_FAST")
          .enabled(false);
        plot
          .line(dataTable.mapAs({ value: 15 }))
          .stroke("1 #FF33C1")
          .name("MA_SLOW")
          .enabled(false);
        plot
          .line(dataTable.mapAs({ value: 16 }))
          .stroke("1 #FFA833")
          .name("MA_MEDIUM")
          .enabled(false);

        plot
          .line(dataTable.mapAs({ value: 32 }))
          .stroke("1 #c20b33")
          .name("EMA_FAST")
          .enabled(false);
        plot
          .line(dataTable.mapAs({ value: 33 }))
          .stroke("1 #fa8072")
          .name("EMA_SLOW")
          .enabled(false);
        plot
          .line(dataTable.mapAs({ value: 34 }))
          .stroke("1 #107dac")
          .name("EMA_MEDIUM")
          .enabled(false);

        plot
          .line(dataTable.mapAs({ value: 17 }))
          .stroke("1 #0ce3ac")
          .name("BBANDS_LOWER")
          .enabled(false);
        plot
          .line(dataTable.mapAs({ value: 18 }))
          .stroke("1 #ffb00b")
          .name("BBANDS_MIDDLE")
          .enabled(false);
        plot
          .line(dataTable.mapAs({ value: 19 }))
          .stroke("1 #ff4200")
          .name("BBANDS_UPPER")
          .enabled(false);

        let peakplot = dataTable.mapAs({ value: 20 });
        let peaklowline = chart.plot(1).line(peakplot);
        peaklowline.name("PEAK");
        peaklowline.stroke("#444 0.9");
        peaklowline.enabled(false);

        let bottomplot = dataTable.mapAs({ value: 21 });
        let bottomlowline = chart.plot(1).line(bottomplot);
        bottomlowline.name("BOTTOM");
        bottomlowline.stroke("#000 0.9");
        bottomlowline.enabled(false);

        let upTrendAngleplot = dataTable.mapAs({ value: 29 });
        let upTrendAngleline = chart.plot(1).line(upTrendAngleplot);
        upTrendAngleline.name("PEAK_TREND_DISTANCE");
        upTrendAngleline.stroke("#000 0.9");
        upTrendAngleline.enabled(false);

        let downTrendAngleplot = dataTable.mapAs({ value: 30 });
        let downTrendAngleline = chart.plot(1).line(downTrendAngleplot);
        downTrendAngleline.name("BOTTOM_TREND_DISTANCE");
        downTrendAngleline.stroke("#000 0.9");
        downTrendAngleline.enabled(false);

        let candleNumber = dataTable.mapAs({ value: 31 });
        let candleline = chart.plot(1).line(candleNumber);
        candleline.name("CANDLE_NUM");
        candleline.stroke("#fcafac 0.9");
        candleline.enabled(false);

        let legend = chart.plot(1).legend();
        legend.listen("legendItemClick", function (e) {
          let index = e["itemIndex"];

          const seriesName = chart.plot(1).getSeriesAt(index).name();
          if (!chart.plot(1).getSeriesAt(index).enabled()) {
            const max = result.reduce(function (prev, current) {
              return prev[seriesName] > current[seriesName] ? prev : current;
            });
            const min = result.reduce(function (prev, current) {
              return prev[seriesName] < current[seriesName] ? prev : current;
            });

            chart.plot(1).yScale().softMaximum(max[seriesName]);
            chart.plot(1).yScale().softMinimum(min[seriesName]);
          } else {
            chart.plot(1).yScale().softMaximum(null);
            chart.plot(1).yScale().softMinimum(null);
          }
        });

        const myNode = document.getElementById("container");
        myNode.innerHTML = "";

        if (!chart.container()) chart.container("container");
        plot.yGrid(false).xGrid(false).yMinorGrid(false).xMinorGrid(false);
        let crosshair = chart.crosshair();
        crosshair.enabled(true);

        // Set display mode.
        crosshair.displayMode("float");

        chart
          .crosshair()
          .yLabel()
          .format(function () {
            return this.rawValue.toFixed(
              formData.currency.includes("JPY") ? 3 : 5
            );
          })
          .offsetX(-80);

        let grouping = chart.grouping();
        grouping.enabled(false);
        chart.draw();
        setChartGlobals(chart);
        setOpenFilter(false);
        let rangeSelector = anychart.ui.rangeSelector();
        rangeSelector.render(chart);

        if (result.length > 300) {
          chart.selectRange(result[result.length - 300].datetime);
        }

        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
        notification.error({
          message: "No data found!",
        });
        const myNode = document.getElementById("container");
        myNode.innerHTML = "";
      });
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      let controller = plotGlobal.annotations();
      if (dateSearched) {
        let annotationsCount = plotGlobal.annotations().getAnnotationsCount();
        plotGlobal.annotations().removeAnnotationAt(annotationsCount - 1);
        console.log("annotationsCount", annotationsCount);
        console.log("controller", controller);
      }

      let verticalLine = controller.verticalLine();
      verticalLine.xAnchor(event.target.value);
      verticalLine.normal().stroke("#ccc", 1, "10 3");
      verticalLine.allowEdit(false);
      setDateSearched(true);
      chartGlobals.selectRange(moment(event.target.value).subtract(1, "days"));
    }
  };

  //=================================== Analysis =================================================================

  const onFinishAnalysis = (values) => {
    setLoader(true);
    onFinish(submitedData);
    let formData = {};
    formData = {
      from: `${yearFrom}-${monthFrom}-${dayFrom}`,
      to: `${yearTo}-${monthTo}-${dayTo}`,
      currencies: [currencies],
      timeframe: timeframe,

      sell: {
        success: {
          gain_pips: values.sell_success_gain_pips,
        },
        failed: {
          gain_pips: values.sell_failure_gain_pips,
        },
      },
      buy: {
        success: {
          gain_pips: values.buy_success_gain_pips || 50,
        },
        failed: {
          gain_pips: values.buy_failure_gain_pips || -20,
        },
      },
      trading_strategy: {
        trading_strategy_A: {
          flat_candles: {
            status: true,
            num_candles: values.num_candles,
            tolerance_level: values.tolerance_level,
          },
          moving_average: {
            status: values.isMovingAvg || false,
            type: values.type || "Medium",
            before_candles: values.before_candles || 10,
            after_candles: values.after_candles || 10,
          },
        },
      },
      detail_report: detailReport,
    };

    setDataAnalysis(null);
    setPath(null);

    api({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_CURRENCY_STRENGTH}pattern_detection`,
      data: formData,
    })
      .then((res) => {
        setDataAnalysis(res.data.data);
        setPath(res.data.path);
        setLoader(false);
        setOpenFilter(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
        setOpenFilter(false);
      });
  };

  const drawDetailAnnoation = (text, record, index) => {
    if (dateSearched) {
      let annotationsCount = plotGlobal.annotations().getAnnotationsCount();
      plotGlobal.annotations().removeAnnotationAt(annotationsCount - 1);
    }

    plotGlobal
      .annotations()
      .verticalRange({
        xAnchor: record.entry_time,
        secondXAnchor: record.exit_time,
        fill: record.gain_target_hit === "success" ? "green 0.3" : "red 0.3",
        stroke: 0,
      })
      .allowEdit(false); // disable edit mode

    setDateSearched(true);
    setRowActive(index);
    chartGlobals.selectRange(moment(record.entry_time).subtract(1, "days"));
  };

  const columnsSummary = [
    {
      title: "Success",
      dataIndex: "Total_success",
      key: "Total_success",
    },
    {
      title: "Failure",
      dataIndex: "Total_failed",
      key: "Total_failed",
    },
    {
      title: "Pips Gained",
      dataIndex: "Total_pips_gained",
      key: "Total_pips_gained",
    },
    {
      title: "Pips Lost",
      dataIndex: "Total_pips_lost",
      key: "Total_pips_lost",
    },
    {
      title: "Net Profit",
      dataIndex: "Net_profit",
      key: "Net_profit",
    },
    {
      title: "Min Trades/Day",
      dataIndex: "Min_trades_day",
      key: "Min_trades_day",
    },
    {
      title: "Average_1 Trades/Day",
      dataIndex: "Average_1_trades_day",
      key: "Average_1_trades_day",
    },
    {
      title: "Average_2 Trades/Day",
      dataIndex: "Average_2_trades_day",
      key: "Average_2_trades_day",
    },
    {
      title: "Max Trades/Day",
      dataIndex: "Max_trades_day",
      key: "Max_trades_day",
    },
    {
      title: "Total Trades/Year",
      dataIndex: "Total_trades_year",
      key: "Total_trades_year",
    },
  ];

  const columnsDetail = [
    {
      title: "S.No",
      dataIndex: "key",
      key: "key",
      render: (text, record) => parseInt(text + 1),
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: "entry_time",
      dataIndex: "entry_time",
      key: "entry_time",
      render: (text, record, index) => (
        <a onClick={() => drawDetailAnnoation(text, record, index)}>{text}</a>
      ),
    },
    {
      title: "exit_time",
      dataIndex: "exit_time",
      key: "exit_time",
    },
    {
      title: "cc_pattern_start_date",
      dataIndex: "cc_pattern_start_date",
      key: "cc_pattern_start_date",
    },
    {
      title: "cc_pattern_end_date",
      dataIndex: "cc_pattern_end_date",
      key: "cc_pattern_end_date",
    },

    {
      title: "cc_pattern",
      dataIndex: "cc_pattern",
      key: "cc_pattern",
    },
    {
      title: "cc_pattern_direction",
      dataIndex: "cc_pattern_direction",
      key: "cc_pattern_direction",
      filters: [
        {
          text: "buy",
          value: "buy",
        },
        {
          text: "sell",
          value: "sell",
        },
      ],
      onFilter: (value, record) =>
        record.cc_pattern_direction.indexOf(value) === 0,
    },
    {
      title: "entry_price",
      dataIndex: "entry_price",
      key: "entry_price",
    },
    {
      title: "exit_price",
      dataIndex: "exit_price",
      key: "exit_price",
    },
    {
      title: "gain/loss pips",
      dataIndex: "gain/loss pips",
      key: "gain/loss pips",
    },
    {
      title: "gain_target_hit",
      dataIndex: "gain_target_hit",
      key: "gain_target_hit",
      filters: [
        {
          text: "success",
          value: "success",
        },
        {
          text: "failed",
          value: "failed",
        },
      ],
      onFilter: (value, record) => record.gain_target_hit.indexOf(value) === 0,
    },
    {
      title: "Detail",
      key: "action",
      render: (_, record) => (
        <Popover
          content={content}
          placement="leftTop"
          trigger="click"
          onOpenChange={() => rowSummary(record)}
        >
          <MoreOutlined className="ico-lg" />
        </Popover>
      ),
    },
  ];
  let content = (
    <div>
      {recordSummary &&
        recordSummary.map((item, key) => (
          <div className="candle-summary-item" key={item.key}>
            {item.key}: {item.value}
          </div>
        ))}
    </div>
  );
  const rowSummary = (record) => {
    setRecordSummary(null);
    const candles = [];
    for (let key in record) {
      if (!key.startsWith("candle_")) continue;
      let candles_object = {
        key: key,
        value: record[key],
      };
      candles.push(candles_object);
    }
    if (candles.length > 0) {
      setRecordSummary(candles);
    }
    //ant-table-row-selected
  };

  const downloadDetailResult = () => {
    setLoader(true);
    api
      .get(
        `${process.env.REACT_APP_CURRENCY_STRENGTH}detail_report?path=${path}`,

        { responseType: "blob" }
      )
      .then(({ data: blob }) => {
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.download = "DetailResults.zip";
        link.click();
        setLoader(false);
      });
  };

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="economic-events">
          <div className="card">
            <div className="card-header">
              <Row gutter={16}>
                <Col span={16}>
                  <div
                    className="header-title cursor-pointer"
                    onClick={showFilter}
                  >
                    <span>
                      <InteractionOutlined />
                      {submitedData && submitedData.currency}
                    </span>
                    <span>
                      <FieldTimeOutlined />
                      {submitedData && submitedData.timeframe}
                    </span>
                    <span>
                      <CalendarOutlined />
                      {submitedData &&
                        moment(submitedData.from).format("YYYY-MM-DD")}{" "}
                      -{" "}
                      {submitedData &&
                        moment(submitedData.to).format("YYYY-MM-DD")}
                    </span>
                    <MoreOutlined />
                  </div>
                </Col>

                <Col span={8}>
                  <div className="search-date mr-10">
                    <Input
                      onKeyDown={handleKeyDown}
                      placeholder="YYYY-MM-DD HH:mm"
                    />
                    <div
                      className="header-title cursor-pointer text-right"
                      onClick={showFilter}
                    ></div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="card-body">
              <div className="split-half">
                <div
                  id="container"
                  className={` ${
                    dataAnalysis === null
                      ? "chart-section-full"
                      : "chart-section-half"
                  }`}
                ></div>
                {dataAnalysis ? (
                  <div className="table-section">
                    <div className="download-button">
                      {path ? (
                        <Button
                          type="primary"
                          className="btn-theme cursor-pointer"
                          onClick={downloadDetailResult}
                        >
                          Download
                        </Button>
                      ) : null}
                    </div>
                    <Tabs defaultActiveKey="1">
                      <Tabs.TabPane tab="Detail Results" key="1">
                        <div className="table-detail">
                          {dataAnalysis.map((item, index) => {
                            return (
                              <div className="table-responsive" key={item.key}>
                                <Table
                                  columns={columnsDetail}
                                  dataSource={item.detail}
                                  pagination={
                                    item.detail.length > 10 ? true : false
                                  }
                                  rowClassName={(record, index) =>
                                    rowActive === index
                                      ? "ant-table-row-selected"
                                      : "none"
                                  }
                                />
                              </div>
                            );
                          })}
                        </div>
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Summary" key="2">
                        <div className="table-summary">
                          {dataAnalysis.map((item, index) => {
                            return (
                              <div key={index}>
                                <Table
                                  columns={columnsSummary}
                                  dataSource={[item.summary]}
                                  pagination={false}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </Tabs.TabPane>
                    </Tabs>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className={`toggle-statistics ${isStatistics ? "active" : ""}`}
            >
              <div className="icon-toggle" onClick={toggleStatistic}>
                {isStatistics ? <DownOutlined /> : <UpOutlined />}
              </div>
            </div>
            <div className={`statistics-panel ${isStatistics ? "active" : ""}`}>
              <div className="statistics-item">
                <span>Hammers: </span>
                <span>{hammerCount}</span>
              </div>
              <div className="statistics-item">
                <span>Inverted Hammers: </span>
                <span>{invertedCount}</span>
              </div>
              <div className="statistics-item">
                <span>Highs: </span>
                <span>{highsCount}</span>
              </div>
              <div className="statistics-item">
                <span>Lows: </span>
                <span>{lowsCount}</span>
              </div>
              <div className="statistics-item">
                <span>Support: </span>
                <span>{supportCount}</span>
              </div>
              <div className="statistics-item">
                <span>Resistance: </span>
                <span>{resistanceCount}</span>
              </div>
              <div className="statistics-item">
                <span>HighsTrends: </span>
                <span>{uptrendCount}</span>
              </div>
              <div className="statistics-item">
                <span>LowsTrends: </span>
                <span>{downtrendCount}</span>
              </div>
              <div className="statistics-item">
                <span>Flags: </span>
                <span>{flagsCount}</span>
              </div>
              {/* <a
                style={{ fontSize: "14px", textDecoration: "none" }}
                href="new-design"
              >
                New design
              </a> */}
            </div>
          </div>

          <Drawer
            placement="right"
            width={750}
            onClose={onCloseFilter}
            open={openFilter}
            closable={false}
          >
            <Form form={form} layout="vertical" initialValues={valuesDefault}>
              <Row gutter={16}>
                <Col span={12}>
                  <div className="title-box">
                    <h3>From</h3>
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item label="Year" name="yearFrom">
                          <Select style={{ width: "100%" }}>
                            <Option value="2010">2010</Option>
                            <Option value="2011">2011</Option>
                            <Option value="2012">2012</Option>
                            <Option value="2013">2013</Option>
                            <Option value="2014">2014</Option>
                            <Option value="2015">2015</Option>
                            <Option value="2016">2016</Option>
                            <Option value="2017">2017</Option>
                            <Option value="2018">2018</Option>
                            <Option value="2019">2019</Option>
                            <Option value="2020">2020</Option>
                            <Option value="2021">2021</Option>
                            <Option value="2022">2022</Option>
                            <Option value="2023">2023</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Month" name="monthFrom">
                          <Select style={{ width: "100%" }}>
                            <Option value="01">01</Option>
                            <Option value="02">02</Option>
                            <Option value="03">03</Option>
                            <Option value="04">04</Option>
                            <Option value="05">05</Option>
                            <Option value="06">06</Option>
                            <Option value="07">07</Option>
                            <Option value="08">08</Option>
                            <Option value="09">09</Option>
                            <Option value="10">10</Option>
                            <Option value="11">11</Option>
                            <Option value="12">12</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Day" name="dayFrom">
                          <Select style={{ width: "100%" }}>
                            <Option value="01">01</Option>
                            <Option value="02">02</Option>
                            <Option value="03">03</Option>
                            <Option value="04">04</Option>
                            <Option value="05">05</Option>
                            <Option value="06">06</Option>
                            <Option value="07">07</Option>
                            <Option value="08">08</Option>
                            <Option value="09">09</Option>
                            <Option value="10">10</Option>
                            <Option value="11">11</Option>
                            <Option value="12">12</Option>
                            <Option value="13">13</Option>
                            <Option value="14">14</Option>
                            <Option value="15">15</Option>
                            <Option value="16">16</Option>
                            <Option value="17">17</Option>
                            <Option value="18">18</Option>
                            <Option value="19">19</Option>
                            <Option value="20">20</Option>
                            <Option value="21">21</Option>
                            <Option value="22">22</Option>
                            <Option value="23">23</Option>
                            <Option value="24">24</Option>
                            <Option value="25">25</Option>
                            <Option value="26">26</Option>
                            <Option value="27">27</Option>
                            <Option value="28">28</Option>
                            <Option value="29">29</Option>
                            <Option value="30">30</Option>
                            <Option value="31">31</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="title-box">
                    <h3>To</h3>
                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item label="Year" name="yearTo">
                          <Select style={{ width: "100%" }}>
                            <Option value="2010">2010</Option>
                            <Option value="2011">2011</Option>
                            <Option value="2012">2012</Option>
                            <Option value="2013">2013</Option>
                            <Option value="2014">2014</Option>
                            <Option value="2015">2015</Option>
                            <Option value="2016">2016</Option>
                            <Option value="2017">2017</Option>
                            <Option value="2018">2018</Option>
                            <Option value="2019">2019</Option>
                            <Option value="2020">2020</Option>
                            <Option value="2021">2021</Option>
                            <Option value="2022">2022</Option>
                            <Option value="2023">2023</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Month" name="monthTo">
                          <Select style={{ width: "100%" }}>
                            <Option value="01">01</Option>
                            <Option value="02">02</Option>
                            <Option value="03">03</Option>
                            <Option value="04">04</Option>
                            <Option value="05">05</Option>
                            <Option value="06">06</Option>
                            <Option value="07">07</Option>
                            <Option value="08">08</Option>
                            <Option value="09">09</Option>
                            <Option value="10">10</Option>
                            <Option value="11">11</Option>
                            <Option value="12">12</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label="Day" name="dayTo">
                          <Select style={{ width: "100%" }}>
                            <Option value="01">01</Option>
                            <Option value="02">02</Option>
                            <Option value="03">03</Option>
                            <Option value="04">04</Option>
                            <Option value="05">05</Option>
                            <Option value="06">06</Option>
                            <Option value="07">07</Option>
                            <Option value="08">08</Option>
                            <Option value="09">09</Option>
                            <Option value="10">10</Option>
                            <Option value="11">11</Option>
                            <Option value="12">12</Option>
                            <Option value="13">13</Option>
                            <Option value="14">14</Option>
                            <Option value="15">15</Option>
                            <Option value="16">16</Option>
                            <Option value="17">17</Option>
                            <Option value="18">18</Option>
                            <Option value="19">19</Option>
                            <Option value="20">20</Option>
                            <Option value="21">21</Option>
                            <Option value="22">22</Option>
                            <Option value="23">23</Option>
                            <Option value="24">24</Option>
                            <Option value="25">25</Option>
                            <Option value="26">26</Option>
                            <Option value="27">27</Option>
                            <Option value="28">28</Option>
                            <Option value="29">29</Option>
                            <Option value="30">30</Option>
                            <Option value="31">31</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </Col>

                <Col span={6}>
                  <Form.Item label="Currencies" name="currencies">
                    <Select
                      showSearch
                      placeholder="Select"
                      style={{ width: "100%" }}
                    >
                      <Option value="EUR_USD">EUR_USD</Option>
                      <Option value="USD_JPY">USD_JPY</Option>
                      <Option value="GBP_USD">GBP_USD</Option>
                      <Option value="USD_CAD">USD_CAD</Option>
                      <Option value="USD_CHF">USD_CHF</Option>
                      <Option value="EUR_JPY">EUR_JPY</Option>

                      {/* <Option value="AUD_USD">AUD_USD</Option>
                      <Option value="CAD_JPY">CAD_JPY</Option> */}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item label="Time Frames" name="timeframe">
                    <Select style={{ width: "100%" }}>
                      <Option value="M5">M5</Option>
                      <Option value="M10">M10</Option>
                      <Option value="M15">M15</Option>
                      <Option value="M30">M30</Option>
                      <Option value="M60">M60</Option>
                      <Option value="M240">M240</Option>
                      <Option value="M1440">M1440</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="REI" name="REI">
                    <InputNumber style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="DEMARKER" name="DEMARKER">
                    <InputNumber style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane tab="Annotation" key="1">
                <Form
                  name="horizontal_login"
                  layout="vertical"
                  initialValues={valuesDefault}
                  onFinish={onFinish}
                >
                  <Collapse bordered={false}>
                    <Panel header="EMA" key="0">
                      <div>
                        <Row gutter={16}>
                          <Col span={8}>
                            <Form.Item
                              label="EMA_SLOW"
                              name="TIMEPERIOD_SLOW_EMA"
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              label="EMA_MEDIUM"
                              name="TIMEPERIOD_MEDIUM_EMA"
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              label="EMA_FAST"
                              name="TIMEPERIOD_FAST_EMA"
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Panel>
                    <Panel header="Moving Average" key="1">
                      <div>
                        <Row gutter={16}>
                          <Col span={8}>
                            <Form.Item
                              label="MA_SLOW"
                              name="TIMEPERIOD_SLOW_MA"
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              label="MA_MEDIUM"
                              name="TIMEPERIOD_MEDIUM_MA"
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              label="MA_FAST"
                              name="TIMEPERIOD_FAST_MA"
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Panel>
                    <Panel header="BANDS" key="2">
                      <div>
                        <Row gutter={16}>
                          <Col span={24}>
                            <Form.Item
                              label="TIMEPERIOD_BBANDS"
                              name="TIMEPERIOD_BBANDS"
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Panel>
                    <Panel header="HAMMER TEST" key="3">
                      <div>
                        <Row gutter={16}>
                          <Col span={3}>
                            <Form.Item
                              label="Wick"
                              name="TIMEFRAME_TO_CANDLE_PATTERN_TEST"
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="5Min" name="size5Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="10Min" name="size10Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="15Min" name="size15Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="30Min" name="size30Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="60Min" name="size60Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="240Min" name="size240Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="1D" name="sizeOneD">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Panel>
                    <Panel header="CANDLE_PEAK_X" key="4">
                      <div>
                        <Row gutter={16}>
                          <Col span={3}>
                            <Form.Item label="5Min" name="PEAK_X5Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="10Min" name="PEAK_X10Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="15Min" name="PEAK_X15Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="30Min" name="PEAK_X30Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="60Min" name="PEAK_X60Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="240Min" name="PEAK_X240Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="1D" name="PEAK_XOneD">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Panel>
                    <Panel header="PEAK_LEVEL_WINDOW" key="5">
                      <div>
                        <Row gutter={16}>
                          <Col span={3}>
                            <Form.Item label="5Min" name="LEVEL_WINDOW5Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="10Min" name="LEVEL_WINDOW10Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="15Min" name="LEVEL_WINDOW15Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="30Min" name="LEVEL_WINDOW30Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="60Min" name="LEVEL_WINDOW60Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item
                              label="240Min"
                              name="LEVEL_WINDOW240Mins"
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="1D" name="WINDOWOneD">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Panel>
                    <Panel header="PEAK_LEVEL_RANGE" key="6">
                      <div>
                        <Row gutter={16}>
                          <Col span={3}>
                            <Form.Item label="5Min" name="LEVEL_RANGE5Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="10Min" name="LEVEL_RANGE10Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="15Min" name="LEVEL_RANGE15Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="30Min" name="LEVEL_RANGE30Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="60Min" name="LEVEL_RANGE60Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="240Min" name="LEVEL_RANGE240Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="1D" name="RANGEOneD">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Panel>
                    <Panel header="Trend Lines" key="7">
                      <div>
                        <Row gutter={16}>
                          <Col span={3}>
                            <Form.Item label="5Min" name="trend5Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="10Min" name="trend10Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="15Min" name="trend15Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="30Min" name="trend30Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="60Min" name="trend60Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="240Min" name="trend240Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="1D" name="trendOneD">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Panel>
                    <Panel header="Flags" key="8">
                      <div>
                        <Row gutter={16}>
                          <Col span={3}>
                            <Form.Item label="5Min" name="FLAGS_FLAGS5Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="10Min" name="FLAGS_FLAGS10Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="15Min" name="FLAGS_FLAGS15Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="30Min" name="FLAGS_FLAGS30Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="60Min" name="FLAGS_FLAGS60Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="240Min" name="FLAGS_FLAGS240Mins">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={3}>
                            <Form.Item label="1D" name="FLAGSOneD">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              label="Tolerance pips"
                              name="tolerance_pips"
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>

                          <Col span={8}>
                            <Form.Item
                              label="Min level hits"
                              name="min_level_hits"
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item label="Flag height" name="flag_height">
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </Panel>
                  </Collapse>
                  <br />

                  <Form.Item name="annotations" className="checkbox-list">
                    <Checkbox.Group options={filterAnotation} />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      className="btn-theme"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Analysis" key="2">
                <Form
                  name="horizontal_login"
                  onFinish={onFinishAnalysis}
                  initialValues={valuesDefaultAnalysis}
                  layout="vertical"
                >
                  <Row gutter={16}>
                    <Col span={24}>
                      <div className="title-box">
                        <h3>Buy</h3>
                        <div className="buy-box">
                          <Row gutter={8}>
                            <Col span={6}>
                              <Form.Item
                                label="Gain Pips For Success"
                                name="buy_success_gain_pips"
                              >
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>

                            <Col span={6}>
                              <Form.Item
                                label="Loss Pips For Failed"
                                name="buy_failure_gain_pips"
                              >
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="title-box">
                        <h3>Sell</h3>
                        <div className="sell-box">
                          <Row gutter={8}>
                            <Col span={6}>
                              <Form.Item
                                label="Gain Pips For Success"
                                name="sell_success_gain_pips"
                              >
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>

                            <Col span={6}>
                              <Form.Item
                                label="Loss Pips For Failed"
                                name="sell_failure_gain_pips"
                              >
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Collapse defaultActiveKey={["1"]}>
                    <Panel header="Trading Strategy A" key="1">
                      <Tabs defaultActiveKey="1">
                        <Tabs.TabPane tab="Flat Candles" key="1">
                          <div>
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  label="No of candles"
                                  name="num_candles"
                                >
                                  <InputNumber style={{ width: "100%" }} />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  label="Tolerance level"
                                  name="tolerance_level"
                                >
                                  <InputNumber style={{ width: "100%" }} />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Moving Average" key="2">
                          <div>
                            <Form.Item
                              valuePropName="checked"
                              name="isMovingAvg"
                            >
                              <Checkbox
                                onChange={() =>
                                  setApplyMovingAvg(!applyMovingAvg)
                                }
                              >
                                Apply Moving Average
                              </Checkbox>
                            </Form.Item>
                            {applyMovingAvg ? (
                              <Row gutter={16}>
                                <Col span={8}>
                                  <Form.Item label="Type" name="type">
                                    <Select style={{ width: "100%" }}>
                                      <Option value="Slow">Slow</Option>
                                      <Option value="Medium">Medium</Option>
                                      <Option value="Fast">Fast</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    label="No. candles before flat candle"
                                    name="before_candles"
                                  >
                                    <InputNumber style={{ width: "100%" }} />
                                  </Form.Item>
                                </Col>
                                <Col span={8}>
                                  <Form.Item
                                    label="No. candles after flat candle"
                                    name="after_candles"
                                  >
                                    <InputNumber style={{ width: "100%" }} />
                                  </Form.Item>
                                </Col>
                              </Row>
                            ) : (
                              ""
                            )}
                          </div>
                        </Tabs.TabPane>
                      </Tabs>
                    </Panel>
                  </Collapse>
                  <br /> <br />
                  <Row gutter={16}>
                    <Col span={24} className="text-right">
                      <div className="btn-group-analysis">
                        <Button
                          type="primary"
                          className="btn-theme"
                          htmlType="submit"
                          onClick={() => setDetailReport(false)}
                        >
                          Generate Summary
                        </Button>
                        <Button
                          type="primary"
                          className="btn-theme"
                          htmlType="submit"
                          onClick={() => setDetailReport(true)}
                        >
                          Generate Detail Results
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Tabs.TabPane>
            </Tabs>
          </Drawer>

          {loader ? (
            <div className="loader">
              <div className="linespinner"></div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Indicators;
