import React from "react";
import Sidebar from "../components/Sidebar";
import { Tabs } from "antd";
import ModelResultsCSV from "../components/ModelResultsCSV";
export const ModelResults = () => {
  return (
    <>
      <Sidebar />
      <div className="main-content">
        <ModelResultsCSV />
      </div>
    </>
  );
};
