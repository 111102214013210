import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Tabs,
  DatePicker,
  Form,
  Input,
  Button,
  Typography,
  notification,
  Drawer,
  InputNumber,
  Select,
  Space,
} from "antd";
import ConfigDetail from "../components/ConfigDetail";
import { v4 as uuid } from "uuid";
import axios from "axios";
import anychart from "anychart";
import {
  ReadOutlined,
  FieldTimeOutlined,
  FileExcelOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import moment from "moment";
import logo from "../assets/images/logo.svg";
import api from "../utils/api";
const { Text } = Typography;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";

const LiveData = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [formHandleWeek] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [openConfig, setOpenConfig] = useState(false);
  const [openConfigBlackout, setOpenConfigBlackout] = useState(false);
  const [fileJson, setFileJson] = useState(null);
  const [configData, setConfigData] = useState([]);
  const [settingsData, setSettingsData] = useState([]);
  const [pattens, setPatterns] = useState([]);
  const [patternSocketTime, setPatternSocketTime] = useState(null);
  const [positionsSocketTime, setPositionsSocketTime] = useState(null);
  const [tradeHistory, setTradeHistory] = useState([]);
  const [dataSummary, setDataSummary] = useState([]);
  const [PLResponse, setPLResponse] = useState([]);
  const [rowActive, setRowActive] = useState(null);
  const [winingTradesBar, setWiningTradesBar] = useState(null);
  const [losingTradesBar, setLosingTradesBar] = useState(null);
  const [pipsLostBar, setPipsLostBar] = useState(null);
  const [pipsGainedBar, setPipsGainedBar] = useState(null);
  const [rowMore, setRowMore] = useState(null);
  const [dateIntialValues, setdateIntialValues] = useState([
    {
      datetime: [
        moment().subtract(1, "weeks").startOf("week").add(7, "days"),
        moment(new Date()),
      ],
    },
  ]);
  const [timeframesArray, setTimeframesArray] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const unique_id = uuid();
  const { RangePicker } = DatePicker;
  const URL = "wss://luccro.infosiphon.com/economic_data/pattern_detection/";
  const PL_URL = "wss://luccro.infosiphon.com/economic_data/point_pl/data/";

  useEffect(() => {
    onChangeHistory();
    onChangeHistoryPattern();
    getTradeConfiguration();
    getRunningStreaming();
    callWebSocket();
    document.title = "Trading";
  }, []);

  const showConfig = () => {
    setLoader(true);
    api({
      method: "GET",
      url: `${process.env.REACT_APP_CONFIG}`,
    })
      .then((res) => {
        if (res.status === 200) {
          setConfigData(res.data.results);
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
      });
    setOpenConfig(true);
  };
  const onCloseReadOnly = () => {
    setOpenConfig(false);
  };
  const showBlackoutConfig = () => {
    setOpenConfigBlackout(true);
  };
  const downloadCSV = () => {
    const data =
      "datetime,open,high,low,close,open_AuditId,div_std,ema5,ma14,std_ema514,watch_signal,exit_watch_signal,signal_open,signal_close\n" +
      csvData
        .map(
          (item) =>
            `${item.datetime},${item.open},${item.high},${item.low},${
              item.close
            },${item.open_AuditId},${item.div_std},${item.ema5},${item.ma14},${
              item.std_ema514
            },${item.watch_signal},${item.exit_watch_signal},${
              item.signal_open ? item.signal_open : 0
            },${item.signal_close ? item.signal_close : 0}`
        )
        .join("\n");

    // Create a Blob object with the CSV data
    const blob = new Blob([data], { type: "text/csv" });

    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "liveResults.csv";

    // Trigger a click event to download the file
    document.body.appendChild(a);
    a.click();

    // Clean up by revoking the URL object
    window.URL.revokeObjectURL(url);
  };
  const onCloseBlackout = () => {
    setOpenConfigBlackout(false);
  };
  const handleOnChangeJson = (e) => {
    setFileJson(e.target.files[0]);
  };
  const handleOnSubmitJson = (e) => {
    e.preventDefault();
    const formData = new FormData();
    if (fileJson) {
      formData.append("file", fileJson);
      setLoader(true);
      api({
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
        url: `${process.env.REACT_APP_TRADE}configurations/blackout_event/`,
        data: formData,
      })
        .then((res) => {
          notification.success({
            message: res.data.message,
          });

          setLoader(false);
          setOpenConfigBlackout(false);
        })
        .catch(function (error) {
          console.log(error);
          setLoader(false);
          setOpenConfigBlackout(false);
          notification.error({
            message: "Error file upload",
          });
        });
    }
  };
  const callWebSocket = (event) => {
    //  =============== Parallel Connections ======================
    const PD = new WebSocket(URL);
    const PL = new WebSocket(PL_URL);

    //  =============== Response ======================

    PD.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response.length > 0) {
        setPatterns(response.filter((item) => item.trade_status !== 1));
        setPatternSocketTime(moment().format("YYYY-MM-DD  h:mm:ss a"));
      }
    };

    PL.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response.length > 0) {
        response.forEach((element) => {
          if (element.message === "new_trade_open") {
            if (!("Notification" in window)) {
              console.log("Browser does not support desktop notification");
            } else {
              Notification.requestPermission();
              const options = {
                body: "",
                icon: logo,
                dir: "ltr",
              };

              new Notification(
                `${element.currency_pair} ${element.timeframe} ${element.message}`,
                options
              );
            }
          }
          if (element.message === "trade_close") {
            if (!("Notification" in window)) {
              console.log("Browser does not support desktop notification");
            } else {
              Notification.requestPermission();
              const options = {
                body: "",
                icon: logo,
                dir: "ltr",
              };

              new Notification(
                `${element.currency_pair} ${element.timeframe} ${element.message}`,
                options
              );
            }
          }
        });
        const filteredOpens = response.filter((val) =>
          val.message.includes("trade_open")
        );
        setPLResponse(filteredOpens);
        setPositionsSocketTime(moment().format("YYYY-MM-DD h:mm:ss a"));
      }
    };
    //  =============== Response ======================

    return () => {
      PD.close();
      PL.close();
    };
  };
  const drawOHLC = (text, record, index) => {
    console.log("record", record);
    let ohlcEndPont = "";
    record.pattern_type === ""
      ? (ohlcEndPont = "ohlc_candles/")
      : (ohlcEndPont = "ohlc/");
    // return false;
    setLoader(true);
    setRowActive(null);
    setRowActive(index);
    setRowMore(null);
    setRowMore(record);

    api({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_BASE_URL}${ohlcEndPont}`,
      data: record.trade_open
        ? {
            currency_pair: record.currency_pair,
            timeframe: record.timeframe,
            trade_open: record.watch_signal,
            trade_close: record.trade_close ? record.trade_close : "",
          }
        : {
            currency_pair: record.currency_pair,
            timeframe: record.timeframe,
            limit: 50,
          },
    })
      .then((res) => {
        let result = res.data.ohlc_data ? res.data.ohlc_data : res.data.data;
        let offset = new Date().getTimezoneOffset();
        anychart.format.outputTimezone(offset);
        let dataTable = anychart.data.table();
        let chart = anychart.stock();
        anychart.format.locales.default.numberLocale.decimalsCount = 5;
        let plot = chart.plot();
        let anno = plot.annotations();

        let tradeOpenDate = new Date(record.trade_open);
        let tradeCloseDate = new Date(record.trade_close);
        let previousRecordOpen = null;
        let previousRecordClose = null;

        for (const element of result) {
          let data = element;
          let candleData = [
            data["datetime"],
            data["open"],
            data["high"],
            data["low"],
            data["close"],
            data["ema5"],
            data["ma14"],
            data["div_std"],
            data["std_ema514"],
          ];

          dataTable.addData([candleData]);
          // console.log(record);
          //  Signals Annotation

          if (
            (data["watch_signal"] === "sell" && record.trade_status === 0) ||
            record.trade_status === 1
          ) {
            anno
              .marker()
              .xAnchor(data["datetime"])
              .valueAnchor(data["high"])
              .fill("green")
              .stroke("none")
              .markerType("star5")
              .size(8)
              .offsetY(-20)
              .allowEdit(false);
          }
          if (
            (data["watch_signal"] === "buy" && record.trade_status === 0) ||
            record.trade_status === 1
          ) {
            anno
              .marker()
              .xAnchor(data["datetime"])
              .valueAnchor(data["high"])
              .fill("red")
              .stroke("none")
              .markerType("star5")
              .size(8)
              .offsetY(-20)
              .allowEdit(false);
          }
          if (
            record.watch_signal != "hold" &&
            record.trade_watch_signal != "hold" &&
            record.trade_watch_signal != 0 &&
            record.point_pl
          ) {
            // Positions Annotation
            if (
              record.watch_signal === "buy" ||
              (record.watch_signal === "sell" &&
                record.trade_watch_signal === "hold")
            ) {
              anno
                .marker()
                .xAnchor(data["datetime"])
                .valueAnchor(data["high"])
                .fill("green")
                .stroke("none")
                .markerType("star5")
                .size(8)
                .offsetY(-20)
                .allowEdit(false);
            }
            if (
              (record.watch_signal === "hold" &&
                record.trade_watch_signal === "buy") ||
              record.trade_watch_signal === "sell"
            ) {
              anno
                .marker()
                .xAnchor(data["datetime"])
                .valueAnchor(data["high"])
                .fill("green")
                .stroke("none")
                .markerType("diamond")
                .size(8)
                .offsetY(-20)
                .allowEdit(false);
            }
          }

          // Watch Signal Annotation
          if (
            moment(record.watch_signal).format("YYYY-MM-DD HH:mm") ===
            moment(data["datetime"]).format("YYYY-MM-DD HH:mm")
          ) {
            anno
              .marker()
              .xAnchor(data["datetime"])
              .valueAnchor(data["high"])
              .fill("green")
              .stroke("none")
              .markerType("star5")
              .size(8)
              .offsetY(-20)
              .allowEdit(false);
            data["watch_signal"] = 1;
          } else {
            data["watch_signal"] = 0;
          }

          if (record.trade_close_reason === "std reversal occur") {
            if (
              moment(record.exit_watch_signal_datetime).format(
                "YYYY-MM-DD HH:mm"
              ) === moment(data["datetime"]).format("YYYY-MM-DD HH:mm")
            ) {
              anno
                .marker()
                .xAnchor(data["datetime"])
                .valueAnchor(data["high"])
                .fill("green")
                .stroke("none")
                .markerType("diamond")
                .size(8)
                .offsetY(-20)
                .allowEdit(false);
              data["exit_watch_signal"] = 1;
            }
          } else {
            data["exit_watch_signal"] = 0;
          }
          // Calculated Annotation

          if (record.trade_open) {
            let currentDate = new Date(data["datetime"]);
            if (currentDate < tradeOpenDate) {
              previousRecordOpen = data;
            }
            if (currentDate < tradeCloseDate) {
              previousRecordClose = data;
            }
          }
        }

        if (previousRecordOpen) {
          anno
            .marker()
            .xAnchor(previousRecordOpen["datetime"])
            .valueAnchor(previousRecordOpen["high"])
            .fill("blue")
            .stroke("none")
            .markerType("triangle-down")
            .size(8)
            .offsetY(-20)
            .allowEdit(false);

          previousRecordOpen["signal_open"] = 1;
        }
        if (previousRecordClose) {
          anno
            .marker()
            .xAnchor(previousRecordClose["datetime"])
            .valueAnchor(previousRecordClose["low"])
            .fill("blue")
            .stroke("none")
            .markerType("triangle-up")
            .size(8)
            .offsetY(20)
            .allowEdit(false);
          previousRecordClose["signal_close"] = 1;
        }

        // console.log("data now", result);
        setCsvData(result);
        let mapping = dataTable.mapAs();
        mapping.addField("datetime", 0, "datetime");
        mapping.addField("open", 1, "open");
        mapping.addField("high", 2, "high");
        mapping.addField("low", 3, "low");
        mapping.addField("close", 4, "close");
        mapping.addField("ema5", 5, "ema5");
        mapping.addField("ma14", 6, "ma14");
        mapping.addField("div_std", 7, "div_std");
        mapping.addField("std_ema514", 8, "std_ema514");

        let serires = plot.candlestick(mapping);

        serires.name("OHLC");
        serires.legendItem(false);

        plot
          .line(dataTable.mapAs({ value: 5 }))
          .stroke("1 #5967ff")
          .name("EMA_5")
          .enabled(true);
        plot
          .line(dataTable.mapAs({ value: 6 }))
          .stroke("1 #ff7f7d")
          .name("MA_14")
          .enabled(true);

        let devStd = dataTable.mapAs({ value: 7 });
        let devStdline = chart.plot(1).line(devStd);
        devStdline.name("div_std");
        devStdline.stroke("#00ab5b 0.9");
        devStdline.enabled(true);

        let stdEMA = dataTable.mapAs({ value: 8 });
        let stdEMAline = chart.plot(1).line(stdEMA);
        stdEMAline.name("std_ema514");
        stdEMAline.stroke("#990000 0.9");
        stdEMAline.enabled(true);

        const myNode = document.getElementById("container");
        myNode.innerHTML = "";

        if (!chart.container()) chart.container("container");
        plot.yGrid(false).xGrid(false).yMinorGrid(false).xMinorGrid(false);
        plot.legend().itemsFormat(function () {
          return this.seriesName;
        });
        chart
          .plot(1)
          .legend()
          .itemsFormat(function () {
            return this.seriesName;
          });
        plot.height("70%");
        chart.plot(1).height("30%");
        chart.plot(1).lineMarker(0).value(0).stroke("#000");
        let crosshair = chart.crosshair();
        crosshair.enabled(true);
        crosshair.displayMode("float");
        chart
          .crosshair()
          .yLabel()
          .format(function () {
            return this.rawValue.toFixed(
              record.currency_pair.includes("JPY") ? 3 : 5
            );
          })
          .offsetX(-80);

        var yAxis = chart.plot().yAxis();

        var axisTicks = yAxis.ticks();

        // Disable axis ticks
        axisTicks.enabled(false);

        let grouping = chart.grouping();
        grouping.enabled(false);
        chart.draw();
        //console.log("record", record);

        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
        notification.error({
          message: "Datetime is missing",
        });
        const myNode = document.getElementById("container");
        myNode.innerHTML = "";
        setRowActive(null);
      });
  };
  const columnsSettings = [
    {
      title: "Currency",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Timeframe",
      dataIndex: "timeframe",
      key: "timeframe",
    },
    {
      title: "Stop loss",
      dataIndex: "stop_loss",
      key: "stop_loss",
    },
    {
      title: "Take profit",
      dataIndex: "take_profit",
      key: "take_profit",
    },
    {
      title: "Watch entry std",
      dataIndex: "watch_entry_std",
      key: "watch_entry_std",
    },
    {
      title: "Entry std",
      dataIndex: "entry_std",
      key: "entry_std",
    },
    {
      title: "Exit std",
      dataIndex: "exit_std",
      key: "exit_std",
    },
    {
      title: "Watch exit std",
      dataIndex: "watch_exit_std",
      key: "watch_exit_std",
    },
    {
      title: "Adaptive std",
      dataIndex: "adaptive_std",
      key: "adaptive_std",
    },
  ];

  const columnsPatterns = [
    {
      title: "Currency",
      dataIndex: "currency_pair",
      key: "currency_pair",
      render: (text, record, index) => (
        <a onClick={() => drawOHLC(text, record, index)}>{text}</a>
      ),
      width: "100px",
    },
    {
      title: "TF",
      dataIndex: "timeframe",
      key: "timeframe",
      width: "60px",
    },

    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      width: "200px",
    },
    {
      title: "Watch Signal ",
      dataIndex: "watch_signal",
      key: "watch_signal",
      width: "200px",
    },
  ];
  const columnsTrade = [
    {
      title: "Currency",
      dataIndex: "currency_pair",
      key: "currency_pair",
      render: (text, record, index) => (
        <a onClick={() => drawOHLC(text, record, index)}>{text}</a>
      ),
      filters: [
        {
          text: "EUR_USD",
          value: "EUR_USD",
        },
        {
          text: "USD_JPY",
          value: "USD_JPY",
        },
        {
          text: "GBP_USD",
          value: "GBP_USD",
        },
      ],
      onFilter: (value, record) => record.currency_pair.indexOf(value) === 0,
    },
    {
      title: "TF",
      dataIndex: "timeframe",
      key: "timeframe",
      filters: [
        {
          text: "M10",
          value: "M10",
        },
        {
          text: "M15",
          value: "M15",
        },
      ],
      onFilter: (value, record) => record.timeframe.indexOf(value) === 0,
      width: "40px",
    },
    {
      title: "Dir",
      dataIndex: "trade_direction",
      key: "trade_direction",
      filters: [
        {
          text: "sell",
          value: "sell",
        },
        {
          text: "buy",
          value: "buy",
        },
      ],
      onFilter: (value, record) => record.trade_direction.indexOf(value) === 0,
      width: "40px",
      render: (text, record, index) => (
        <span style={{ textTransform: "capitalize" }}>
          {record.trade_direction}
        </span>
      ),
    },
    {
      title: "Trade Open",
      dataIndex: "trade_open",
      key: "trade_open",
      width: "130px",
      render: (text) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {moment(text).format("YYYY-MM-DD HH:mm")}
        </span>
      ),
    },
    {
      title: "Point PL",
      dataIndex: "point_pl",
      key: "point_pl",
      width: "60px",
    },
    {
      title: "Current Price",
      dataIndex: "current_price",
      key: "current_price",
      render: (text, record, index) => (
        <span>
          {record.current_price ? record.current_price : record.close_price}
        </span>
      ),
    },

    {
      title: "Open Price",
      dataIndex: "open_price",
      key: "open_price",
      sorter: (a, b) => a.open_price - b.open_price,
    },

    {
      title: "Stop Loss",
      dataIndex: "stop_loss",
      render: (text) => <span style={{ color: "#CD0000" }}>{text}</span>,
      sorter: (a, b) => a.stop_loss - b.stop_loss,
    },
    {
      title: "Take Profit",
      dataIndex: "take_profit",
      key: "take_profit",
      render: (text) => <span style={{ color: "#098C26" }}>{text}</span>,
      sorter: (a, b) => a.take_profit - b.take_profit,
    },
    {
      title: "Status",
      dataIndex: "message",
      key: "message",
      render: (text, record, index) => (
        <span>
          {record.message
            ? record.message
            : record.status + "_" + record.gain_target_hit}
        </span>
      ),
    },
  ];
  const columnsSummary = [
    {
      title: "Currency",
      dataIndex: "currency_pair",
      key: "currency_pair",
    },
    {
      title: "Total trades",
      dataIndex: "total_trades",
      key: "total_trades",
      render: (text, record, index) => (
        <span>{record.countFailed + record.countSuccess}</span>
      ),
    },
    {
      title: "Winning trades",
      dataIndex: "winning_trades",
      key: "winning_trades",
      render: (text, record, index) => <span>{record.countSuccess}</span>,
    },
    {
      title: "Losing trades",
      dataIndex: "losing_trades",
      key: "losing_trades",
      render: (text, record, index) => <span>{record.countFailed}</span>,
    },
    {
      title: "Pips gained",
      dataIndex: "total_pips_gained",
      key: "total_pips_gained",
      render: (text, record, index) => (
        <span>{record.total_pips_gained.toFixed(1)}</span>
      ),
    },
    {
      title: "Pips lost",
      dataIndex: "total_pips_lost",
      key: "total_pips_lost",
      render: (text, record, index) => (
        <span>{record.total_pips_lost.toFixed(1)}</span>
      ),
    },
  ];
  const columnsTradeHistory = [
    {
      title: "Currency",
      dataIndex: "currency_pair",
      key: "currency_pair",
      render: (text, record, index) => (
        <a onClick={() => drawOHLC(text, record, index)}>{text}</a>
      ),
      filters: [
        {
          text: "EUR_USD",
          value: "EUR_USD",
        },
        {
          text: "USD_JPY",
          value: "USD_JPY",
        },
        {
          text: "GBP_USD",
          value: "GBP_USD",
        },
      ],
      onFilter: (value, record) => record.currency_pair.indexOf(value) === 0,
    },
    {
      title: "TF",
      dataIndex: "timeframe",
      key: "timeframe",
      filters: [
        {
          text: "M10",
          value: "M10",
        },
        {
          text: "M15",
          value: "M15",
        },
      ],
      onFilter: (value, record) => record.timeframe.indexOf(value) === 0,
      width: "40px",
    },
    {
      title: "Dir",
      dataIndex: "open_trade_direction",
      key: "open_trade_direction",
      filters: [
        {
          text: "sell",
          value: "sell",
        },
        {
          text: "buy",
          value: "buy",
        },
      ],
      onFilter: (value, record) =>
        record.open_trade_direction.indexOf(value) === 0,
      width: "40px",
      render: (text, record, index) => (
        <span style={{ textTransform: "capitalize" }}>
          {record.open_trade_direction}
        </span>
      ),
    },
    {
      title: "Trade Open",
      dataIndex: "trade_open",
      key: "trade_open",
      width: "130px",
      render: (text) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {moment(text).format("YYYY-MM-DD HH:mm")}
        </span>
      ),
    },
    {
      title: "Trade Close",
      dataIndex: "trade_close",
      key: "trade_close",
      width: "130px",
      render: (text) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {moment(text).format("YYYY-MM-DD HH:mm")}
        </span>
      ),
    },
    {
      title: "PointPL",
      dataIndex: "point_pl",
      key: "point_pl",
      width: "60px",
    },

    {
      title: "Open Price",
      dataIndex: "open_price",
      key: "open_price",
      sorter: (a, b) => a.open_price - b.open_price,
    },

    {
      title: "Stop Loss",
      dataIndex: "stop_loss",
      render: (text) => <span style={{ color: "#CD0000" }}>{text}</span>,
      sorter: (a, b) => a.stop_loss - b.stop_loss,
    },
    {
      title: "Take Profit",
      dataIndex: "take_profit",
      key: "take_profit",
      render: (text) => <span style={{ color: "#098C26" }}>{text}</span>,
      sorter: (a, b) => a.take_profit - b.take_profit,
    },
    {
      title: "Status",
      dataIndex: "message",
      key: "message",
      render: (text, record, index) => (
        <span style={{ whiteSpace: "nowrap" }}>
          {record.message
            ? record.message
            : record.status + "_" + record.gain_target_hit}
        </span>
      ),
    },
    {
      title: "Reason",
      dataIndex: "trade_close_reason",
      key: "trade_close_reason",
    },
  ];

  const handlePreviousWeekClick = () => {
    let date = dateIntialValues.datetime;
    //moment().subtract(1, "weeks").startOf("week").add(7, "days")
    const newStartDate = date[0].clone().subtract(1, "weeks").startOf("week");
    const newEndDate = date[1].clone().subtract(1, "weeks").endOf("week");
    setdateIntialValues({
      datetime: [newStartDate, newEndDate],
    });

    formHandleWeek.setFieldsValue({
      datetime: [newStartDate, newEndDate],
    });
  };
  const handleNextWeekClick = () => {
    let date = dateIntialValues.datetime;
    const newStartDate = date[0].clone().add(1, "weeks").startOf("week");
    const newEndDate = date[1].clone().add(1, "weeks").endOf("week");
    setdateIntialValues({
      datetime: [newStartDate, newEndDate],
    });

    formHandleWeek.setFieldsValue({
      datetime: [newStartDate, newEndDate],
    });
  };
  const onChangeHistory = (values) => {
    setLoader(true);
    let formData = {};
    if (values) {
      formData = {
        datetime_from:
          moment(values.datetime[0]).format("YYYY-MM-DD") + " " + "00:00:00",
        datetime_to:
          moment(values.datetime[1]).format("YYYY-MM-DD") + " " + "23:59:00",
      };
    } else {
      formData = {
        datetime_from: moment()
          .subtract(1, "weeks")
          .startOf("week")
          .add(7, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        datetime_to: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
    }

    api({
      method: "post",
      url: `${process.env.REACT_APP_TRADE_HISTORY}`,
      data: formData,
    })
      .then((response) => {
        setTradeHistory(response.data);
        setDataSummary([]);
        setWiningTradesBar(null);
        setLosingTradesBar(null);
        setPipsLostBar(null);
        setPipsGainedBar(null);
        if (response.data.length > 0) {
          let resultSummary = {
            currency_pair: "",
            countFailed: 1,
            countSuccess: 1,
            total_pips_gained: 0,
            total_pips_lost: 0,
          };
          let summaryArray = [];

          response.data.forEach((element) => {
            let isMatched = false;
            let matchedIndex = 0;
            summaryArray.forEach((element1, index) => {
              if (element1.currency_pair === element.currency_pair) {
                isMatched = true;
                matchedIndex = index;
              }
            });
            if (isMatched) {
              if (element.gain_target_hit === "failed") {
                summaryArray[matchedIndex].countFailed++;
                summaryArray[matchedIndex].total_pips_lost +=
                  element["point_pl"];
              } else {
                summaryArray[matchedIndex].countSuccess++;
                summaryArray[matchedIndex].total_pips_gained +=
                  element["point_pl"];
              }
            } else {
              let total_pips_lost = 0;
              let total_pips_gained = 0;
              let countFailed = 0;
              let countSuccess = 0;
              if (element.gain_target_hit === "failed") {
                total_pips_lost += element["point_pl"];
                countFailed = 1;
              } else {
                total_pips_gained += element["point_pl"];
                countSuccess = 1;
              }
              resultSummary = {
                currency_pair: element.currency_pair,
                countFailed: countFailed,
                countSuccess: countSuccess,
                total_pips_gained: total_pips_gained,
                total_pips_lost: total_pips_lost,
              };
              summaryArray.push(resultSummary);
            }
          });

          setDataSummary(summaryArray);
          summaryArray.forEach(
            ({
              countSuccess,
              countFailed,
              total_pips_gained,
              total_pips_lost,
            }) => {
              setWiningTradesBar((oldValue) => oldValue + countSuccess);
              setLosingTradesBar((oldValue) => oldValue + countFailed);
              setPipsLostBar((oldValue) => oldValue + total_pips_gained);
              setPipsGainedBar((oldValue) => oldValue + total_pips_lost);
            }
          );
        }

        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
      });
  };
  const onChangeHistoryPattern = (values) => {
    setLoader(true);
    let formData = {};
    if (values) {
      formData = {
        datetime_from:
          moment(values.datetime[0]).format("YYYY-MM-DD") + " " + "00:00:00",
        datetime_to:
          moment(values.datetime[1]).format("YYYY-MM-DD") + " " + "23:59:00",
      };
    } else {
      formData = {
        datetime_from: moment(new Date())
          .subtract(1, "days")
          .format("YYYY-MM-DD HH:mm:ss"),
        datetime_to: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      };
    }

    api({
      method: "post",
      url: `${process.env.REACT_APP_TRADE_HISTORY_PATTERN}`,
      data: formData,
    })
      .then((response) => {
        if (response.data.length > 0) {
          const counter = {};

          response.data.forEach((ele) => {
            if (counter[ele.message]) {
              counter[ele.message] += 1;
            } else {
              counter[ele.message] = 1;
            }
          });
        }
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
      });
  };
  const positionsTabClicked = (key, event) => {
    const myNode = document.getElementById("container");
    myNode.innerHTML = "";
    setRowActive(null);

    setRowMore(null);

    if (key === "2") {
      onChangeHistory();
      setdateIntialValues({
        datetime: [
          moment().subtract(1, "weeks").startOf("week").add(7, "days"),

          moment(new Date()),
        ],
      });
    }
  };
  const patternsTabClicked = (key, event) => {
    const myNode = document.getElementById("container");
    myNode.innerHTML = "";
    setRowActive(null);

    setRowMore(null);

    if (key === "2") {
      onChangeHistoryPattern();
      setdateIntialValues({
        datetime: [
          moment().subtract(1, "weeks").startOf("week").add(7, "days"),

          moment(new Date()),
        ],
      });
    }
  };

  const handleChangeSettings = (value) => {
    let currentSettings = settingsData.filter(
      (setting) => setting.currency === value
    );
    form.setFieldsValue(currentSettings[0]);
  };
  const getTradeConfiguration = () => {
    setLoader(true);
    api({
      method: "GET",
      url: `${process.env.REACT_APP_TRADE}configurations/details/`,
    })
      .then((res) => {
        if (res.status === 200) {
          setSettingsData(res.data);
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        notification.error({
          message: "Invalid token.",
        });
        localStorage.removeItem("token");
        navigate("/login", { replace: true });
      });
  };
  const getRunningStreaming = () => {
    setLoader(true);
    api({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}ya_mare_galti_hy/get_status/`,
    })
      .then((res) => {
        if (res.status === 200) {
          let runningTimeframe = [];

          if (res.data.running_timeframes.length > 0) {
            res.data.running_timeframes.forEach((item) => {
              runningTimeframe.push({
                id: item.substring(1),
                timeframe: item,
                start: false,
                stop: true,
              });
            });
          }
          if (res.data.stopped_timeframes.length > 0) {
            res.data.stopped_timeframes.forEach((item) => {
              runningTimeframe.push({
                id: item.substring(1),
                timeframe: item,
                start: true,
                stop: false,
              });
            });
          }
          setTimeframesArray([]);
          setTimeframesArray(
            runningTimeframe.sort((a, b) => {
              return a.id - b.id;
            })
          );
          //  console.log(runningTimeframe);

          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        notification.error({
          message: "Invalid token.",
        });
        localStorage.removeItem("token");
        navigate("/login", { replace: true });
      });
  };

  const tradeConfiguration = (data) => {
    setLoader(true);
    api({
      method: "post",
      url: `${process.env.REACT_APP_TRADE}configurations/`,
      data: data,
    })
      .then((res) => {
        if (res.status === 200) {
          notification.success({
            message: "Config updated successfully",
          });

          setLoader(false);
          getTradeConfiguration();
        }
      })
      .catch(function (error) {
        setLoader(false);
        notification.error({
          message: "Error",
        });
      });
  };
  const onSettingSubmit = (values) => {
    setLoader(true);
    tradeConfiguration(values);
  };
  const onStreamingSubmit = (timeframe, type) => {
    setLoader(true);
    const slug = type
      ? "ya_mare_galti_hy_detection"
      : "ya_mare_galti_hy_detection_terminate_thread";
    let formData = {};
    formData.timeframes = [timeframe];
    api({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}${slug}/`,
      data: formData,
    })
      .then((res) => {
        if (res.status === 200) {
          notification.success({
            message: type ? "Started" : "Stopped",
          });
          setLoader(false);
          getRunningStreaming();
        }
      })
      .catch(function (error) {
        setLoader(false);
        notification.error({
          message: "Error",
        });
      });
  };

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="summary-bar">
          <Row gutter={16} align="middle">
            <Col span={20}>
              <div className="summary-bar-flex">
                <div className="summary-bar-item">
                  Winning trades: {winingTradesBar ? winingTradesBar : "0"}
                </div>
                <div className="summary-bar-item">
                  Losing trades: {losingTradesBar ? losingTradesBar : "0"}
                </div>
                <div className="summary-bar-item">
                  Pips gained: {pipsLostBar ? pipsLostBar.toFixed(1) : "0"}
                </div>
                <div className="summary-bar-item">
                  Pips lost: {pipsGainedBar ? pipsGainedBar.toFixed(1) : "0"}
                </div>
                <div className="summary-bar-item font-lg">
                  Net:{" "}
                  {pipsLostBar ? (pipsLostBar + pipsGainedBar).toFixed(1) : "0"}
                </div>
              </div>
            </Col>
            <Col span={4} className="text-right">
              <div className="config-icons">
                <div
                  title="Download CSV"
                  className="config-icon-item"
                  onClick={downloadCSV}
                >
                  {csvData && csvData.length > 0 ? (
                    <FileExcelOutlined className="ico-lg" />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  title="Blackout Timezones"
                  className="config-icon-item"
                  onClick={showBlackoutConfig}
                >
                  <FieldTimeOutlined className="ico-lg" />
                </div>

                <div
                  title="Read Only Config Detail"
                  className="config-icon-item"
                  onClick={showConfig}
                >
                  <ReadOutlined className="ico-lg" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="full-card">
          <div>
            <Row gutter={16}>
              <Col span={12}>
                <div className="table-livedata">
                  <Tabs
                    type="card"
                    defaultActiveKey="1"
                    onTabClick={patternsTabClicked}
                    size={"small"}
                  >
                    <Tabs.TabPane tab="Signals" key="1">
                      <Table
                        columns={columnsPatterns}
                        dataSource={pattens}
                        bordered
                        className="xs-table"
                        pagination={false}
                        scroll={{
                          y: "40vh",
                        }}
                      />
                      <div className="custom-footer">
                        {patternSocketTime
                          ? "Last updated: " + patternSocketTime
                          : "No message!"}
                      </div>
                    </Tabs.TabPane>

                    <Tabs.TabPane tab="Settings" key="2">
                      <div className="setting-form">
                        <Form>
                          <div className="setting-item">
                            <b>Timeframes</b>
                            <b>Actions</b>
                          </div>

                          {timeframesArray.map((v, i) => (
                            <div className="setting-item" key={i}>
                              <span>{v.timeframe}</span>
                              <div>
                                <Space>
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="small"
                                    disabled={v.stop}
                                    onClick={() =>
                                      onStreamingSubmit(v.timeframe, true)
                                    }
                                  >
                                    Start
                                  </Button>
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="small"
                                    disabled={v.start}
                                    onClick={() =>
                                      onStreamingSubmit(v.timeframe, false)
                                    }
                                  >
                                    Stop
                                  </Button>
                                </Space>
                              </div>
                            </div>
                          ))}
                        </Form>
                      </div>
                    </Tabs.TabPane>
                  </Tabs>
                </div>
                <div className="table-livedata">
                  <Tabs
                    type="card"
                    defaultActiveKey="1"
                    size={"small"}
                    onTabClick={positionsTabClicked}
                  >
                    <Tabs.TabPane tab="Positions" key="1">
                      <Table
                        columns={columnsTrade}
                        dataSource={PLResponse}
                        bordered
                        className="xs-table"
                        pagination={false}
                        rowClassName={(record, index) =>
                          rowActive === index
                            ? "ant-table-row-selected"
                            : "none"
                        }
                        scroll={{
                          y: "40vh",
                        }}
                      />
                      <div className="custom-footer">
                        {positionsSocketTime
                          ? "Last updated: " + positionsSocketTime
                          : "No message!"}
                      </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="History" key="2">
                      <div>
                        <div className="table-actions-date">
                          <Form
                            initialValues={dateIntialValues}
                            onFinish={onChangeHistory}
                            form={formHandleWeek}
                          >
                            <Input.Group compact>
                              <Button onClick={handlePreviousWeekClick}>
                                <LeftOutlined />
                              </Button>
                              <Button onClick={handleNextWeekClick}>
                                <RightOutlined />
                              </Button>
                              <Form.Item name="datetime">
                                <RangePicker
                                  style={{ width: "100%" }}
                                  format={dateFormat}
                                />
                              </Form.Item>
                              <Button htmlType="submit">Submit</Button>
                            </Input.Group>
                          </Form>
                        </div>
                        <Table
                          columns={columnsTradeHistory}
                          dataSource={tradeHistory}
                          bordered
                          className="xs-table"
                          pagination={false}
                          rowClassName={(record, index) =>
                            rowActive === index
                              ? "ant-table-row-selected"
                              : "none"
                          }
                          scroll={{
                            y: "60vh",
                          }}
                        />
                      </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Summary" key="3">
                      <Table
                        columns={columnsSummary}
                        dataSource={dataSummary}
                        bordered
                        className="xs-table"
                        pagination={false}
                        summary={(pageData) => {
                          let totalTrades = 0;
                          let winningTrades = 0;
                          let losingTrades = 0;
                          let totalPipsGained = 0;
                          let totalPipsLost = 0;

                          pageData.forEach(
                            ({
                              countSuccess,
                              countFailed,
                              total_pips_gained,
                              total_pips_lost,
                            }) => {
                              totalTrades += countSuccess + countFailed;
                              winningTrades += countSuccess;
                              losingTrades += countFailed;
                              totalPipsGained += total_pips_gained;
                              totalPipsLost += total_pips_lost;
                            }
                          );
                          return (
                            <>
                              <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>
                                  Total
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1}>
                                  <Text>{totalTrades}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={2}>
                                  <Text>{winningTrades}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={3}>
                                  <Text>{losingTrades}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4}>
                                  <Text>{totalPipsGained.toFixed(1)}</Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={5}>
                                  <Text>{totalPipsLost.toFixed(1)}</Text>
                                </Table.Summary.Cell>
                              </Table.Summary.Row>
                            </>
                          );
                        }}
                      />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Settings" key="4">
                      <div className="setting-form">
                        <Form
                          layout="vertical"
                          form={form}
                          onFinish={onSettingSubmit}
                        >
                          <Row gutter={16}>
                            <Col span={4}>
                              <Form.Item label="Currency" name="currency">
                                <Select
                                  placeholder="Select"
                                  style={{ width: "100%" }}
                                  onChange={handleChangeSettings}
                                >
                                  {settingsData?.map((c, index) => (
                                    <Option key={index} value={c.currency}>
                                      {c.currency}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item label="Time Frames" name="timeframe">
                                <Select
                                  placeholder="Select"
                                  style={{ width: "100%" }}
                                >
                                  {settingsData
                                    ?.filter((item, index, self) => {
                                      return (
                                        index ===
                                        self.findIndex(
                                          (t) => t.timeframe === item.timeframe
                                        )
                                      );
                                    })
                                    .map((t, index) => (
                                      <Option key={index} value={t.timeframe}>
                                        {t.timeframe}
                                      </Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item label="Stop loss" name="stop_loss">
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item label="Take profit" name="take_profit">
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item
                                label="Watch entry std"
                                name="watch_entry_std"
                              >
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item label="Entry std" name="entry_std">
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item label="Exit std" name="exit_std">
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item
                                label="Watch exit std"
                                name="watch_exit_std"
                              >
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item
                                label="Adaptive std"
                                name="adaptive_std"
                              >
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </Form>
                      </div>
                      <Table
                        className="xs-table"
                        pagination={false}
                        bordered
                        columns={columnsSettings}
                        dataSource={settingsData}
                      ></Table>
                    </Tabs.TabPane>
                  </Tabs>
                </div>
              </Col>
              <Col span={12}>
                <div id="container" className="live-chart"></div>
                <div className="p-20">
                  {rowMore ? (
                    <div className="decription-verticle">
                      <h4>More detail</h4>
                      <table>
                        <tbody>
                          {rowMore.exit_watch_signal ? (
                            <tr>
                              <td>Exit watch signal</td>
                              <td> {rowMore.exit_watch_signal}</td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {rowMore.trade_close_reason ? (
                            <tr>
                              <td>Trade close reason</td>
                              <td> {rowMore.trade_close_reason}</td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {rowMore.position_id ? (
                            <tr>
                              <td>Position id</td>
                              <td> {rowMore.position_id}</td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {rowMore.audit_open_price ? (
                            <tr>
                              <td>Audit open price:</td>
                              <td>{rowMore.audit_open_price}</td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {rowMore.current_price ? (
                            <tr>
                              <td>Current price:</td>
                              <td> {rowMore.current_price}</td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {rowMore.ma_direction ? (
                            <tr>
                              <td>MA direction:</td>
                              <td>{rowMore.ma_direction}</td>
                            </tr>
                          ) : (
                            ""
                          )}

                          {rowMore.cc_pattern ? (
                            <tr>
                              <td>Pattern:</td>
                              <td> {rowMore.cc_pattern}</td>
                            </tr>
                          ) : (
                            ""
                          )}

                          <tr>
                            <td colSpan="2">
                              <b>Annotations</b>
                            </td>
                          </tr>
                          <tr>
                            <td>Watch signal</td>
                            <td>Star</td>
                          </tr>
                          <tr>
                            <td>Exit watch signal</td>
                            <td>Diamond</td>
                          </tr>
                          <tr>
                            <td>Previous Open</td>
                            <td>triangle-down</td>
                          </tr>
                          <tr>
                            <td>Previous Close</td>
                            <td>triangle-up</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {loader ? (
          <div className="loader">
            <div className="linespinner"></div>
          </div>
        ) : (
          ""
        )}
        <Drawer
          title="Read Only Config Detail"
          placement="right"
          width={900}
          onClose={onCloseReadOnly}
          open={openConfig}
        >
          <ConfigDetail configData={configData} />
        </Drawer>
        <Drawer
          title="Blackout Timezones"
          placement="right"
          onClose={onCloseBlackout}
          open={openConfigBlackout}
        >
          <form>
            <input
              type={"file"}
              id={"jsonFileInput"}
              accept={".json"}
              onChange={handleOnChangeJson}
            />
            <br />
            <br />

            <Button
              onClick={(e) => {
                handleOnSubmitJson(e);
              }}
              type="primary"
              size="large"
              block
            >
              Submit
            </Button>
          </form>
        </Drawer>
      </div>
    </>
  );
};

export default LiveData;
