import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { UploadOutlined } from "@ant-design/icons";
import {
  Row,
  Col,
  Button,
  Drawer,
  Spin,
  notification,
  Divider,
  Form,
} from "antd";
import anychart from "anychart";
import moment from "moment";

const Comparison = () => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    document.title = "Comparison";
  }, []);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const handleOnChange = (e) => {
    let files = e.target.files;
    e.preventDefault();

    if (files.length === 0) {
      notification.error({
        message: "Please select at least one CSV file",
      });
      return;
    }

    const processCSVFiles = (filesArray) => {
      setIsLoading(true);
      const allData = [];
      let filesProcessed = 0;

      const processFile = (file) => {
        if (file.name.endsWith(".csv")) {
          const fileReader = new FileReader();
          fileReader.onload = function (event) {
            const text = event.target.result;
            const fileData = csvFileToArray(text);
            allData.push(fileData);
            filesProcessed++;

            if (filesProcessed === filesArray.length) {
              createChart(allData);
            }
          };
          fileReader.readAsText(file);
        } else {
          notification.error({
            message: 'File "' + file.name + '" is not a CSV file',
          });
          filesProcessed++;
        }
      };

      filesArray.forEach((file) => {
        processFile(file);
      });
    };

    processCSVFiles(Array.from(files));
  };

  const csvFileToArray = (string) => {
    let csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    csvHeader = csvHeader.map((header) => header.trim());
    let csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    // removed empty rows
    csvRows = csvRows.filter((row) => row.replaceAll(",", "").trim() !== "");

    const data = [];
    for (const i of csvRows) {
      const values = i.split(",");
      const obj = {};

      for (let index = 0; index < csvHeader.length; index++) {
        obj[csvHeader[index]] = values[index];
      }
      data.push(obj);
    }

    // Check if datetime key has value with slashes
    let hasDatetimeWithSlashes = data.some((item) => {
      const datetimeValue = item["datetime"]; // Replace with your actual datetime key
      return datetimeValue && datetimeValue.includes("/");
    });

    if (hasDatetimeWithSlashes) {
      notification.error({
        message: "Invalid date format, Use the standard format YYYY-MM-DD",
      });
      return null;
    }

    return data;
  };

  const createChart = (data) => {
    let offset = new Date().getTimezoneOffset();
    anychart.format.outputTimezone(offset);
    let firstDataTable = anychart.data.table();
    let secondDataTable = anychart.data.table();
    let chart = anychart.stock();
    let plot0 = chart.plot(0);
    let plot1 = chart.plot(1);

    for (const element of data[0]) {
      let candleDataFirst = [
        element["datetime"],
        element["open"],
        element["high"],
        element["low"],
        element["close"],
      ];
      firstDataTable.addData([candleDataFirst]);
    }

    for (const element of data[1]) {
      let candleDataSecond = [
        element["datetime"],
        element["open"],
        element["high"],
        element["low"],
        element["close"],
      ];
      secondDataTable.addData([candleDataSecond]);
    }
    let firstMapping = firstDataTable.mapAs();
    firstMapping.addField("datetime", 0, "datetime");
    firstMapping.addField("open", 1, "open");
    firstMapping.addField("high", 2, "high");
    firstMapping.addField("low", 3, "low");
    firstMapping.addField("close", 4, "close");
    let seriesFirst = plot0.candlestick(firstMapping).name("First");
    // seriesFirst.fallingFill("#ef5350");
    // seriesFirst.fallingStroke("#ef5350");
    // seriesFirst.risingFill("#26a69a");
    // seriesFirst.risingStroke("#26a69a");

    let secondMapping = secondDataTable.mapAs();
    secondMapping.addField("datetime", 0, "datetime");
    secondMapping.addField("open", 1, "open");
    secondMapping.addField("high", 2, "high");
    secondMapping.addField("low", 3, "low");
    secondMapping.addField("close", 4, "close");

    let seriresSencond = plot1.candlestick(secondDataTable).name("Second");
    // seriresSencond.fallingFill("#000", 0.05);
    // seriresSencond.fallingStroke("blue", 0.6);
    // seriresSencond.risingFill("#000", 0.05);
    // seriresSencond.risingStroke("blue", 0.6);

    chart.scroller().xAxis(false);
    // let tooltip = chart.tooltip();
    // tooltip.background().fill("#2c2c2c");
    // tooltip.background().stroke("#2c2c2c");
    // tooltip.fontColor("#fff");

    let grouping = chart.grouping();
    grouping.enabled(false);
    chart.crosshair().displayMode("float");
    let crosshair = chart.crosshair();
    crosshair.enabled(true);
    crosshair.displayMode("float");
    chart
      .crosshair()
      .yLabel()
      .format(function () {
        return this.rawValue.toFixed(5);
      })
      .offsetX(-80);

    //plot0.yGrid(false).yMinorGrid(false);

    const myNode = document.getElementById("container");
    myNode.innerHTML = "";
    if (!chart.container()) chart.container("container");
    chart.draw();

    chart.selectRange("days", 5, "first-date", true);

    let rangePicker = anychart.ui.rangePicker();
    rangePicker.render(chart);
    let rangeSelector = anychart.ui.rangeSelector();
    rangeSelector.render(chart);
    setOpen(false);
    setIsLoading(false);
  };

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <Spin spinning={isLoading}>
          <div className="card">
            <div className="card-header select-none">
              <Row gutter={16} align="middle">
                <Col span={4}>
                  <div className="header-title">Comparisons</div>
                </Col>

                <Col span={20} className="text-right">
                  <div className="search-date mr-10">
                    <div className="d-flex">
                      <Button
                        onClick={showDrawer}
                        type="link"
                        icon={<UploadOutlined />}
                      >
                        Upload CSVs
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="card-body">
              <div
                style={{
                  height: "calc(100vh - 100px)",
                }}
                id="container"
              ></div>
            </div>

            <Drawer
              title="Upload CSVs"
              placement="right"
              onClose={onClose}
              width={900}
              open={open}
            >
              <form className="position-relative">
                <div className="title-box pb-20">
                  <h3>Upload a CSV files</h3>

                  <input
                    type={"file"}
                    id={"csvFileInput"}
                    multiple="multiple"
                    accept={".csv"}
                    onChange={handleOnChange}
                  />
                </div>
              </form>

              <Divider dashed />
            </Drawer>
          </div>
        </Spin>
      </div>
    </>
  );
};

export default Comparison;
