import { Navigate } from "react-router-dom";
import { useAuth } from "../utils/auth";

export default function RequireAuth({ children }) {
  const auth = useAuth();
  const token = localStorage.getItem("user");
  if (!token) {
    return <Navigate to="/login" />;
  }
  return children;
}
