import React, { useState, useEffect } from "react";
import { MoreOutlined } from "@ant-design/icons";
import {
  Form,
  Button,
  DatePicker,
  Drawer,
  Row,
  Col,
  Checkbox,
  Input,
} from "antd";

import anychart from "anychart";
import axios from "axios";
import moment from "moment";
import Sidebar from "../components/Sidebar";
const dateFormat = "YYYY-MM-DD";
const valuesDefault = {
  datefrom: moment(new Date()).subtract(1, "months"),
  dateto: moment(new Date()),
  currency: ["EUR"],
  timeframe: ["M15"],
  signal_type: "analog",
};

const Strength = () => {
  const [loader, setLoader] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [dateRangeFrom, setDateRangeFrom] = useState("");
  const [dateRangeTo, setDateRangeTo] = useState("");
  const [startRatio, setStartRatio] = useState(0);
  const [endRatio, setEndRatio] = useState(1);
  const showFilter = () => {
    setOpenFilter(true);
  };
  const onCloseFilter = () => {
    setOpenFilter(false);
  };
  var chartAnalog;
  var chartDigital;

  const currencyOptions = [
    "EUR",
    "GBP",
    "JPY",
    "USD",
    "CHF",
    "CAD",
    "AUD",
    "NZD",
  ];
  const timeframeOptions = ["M15", "M30", "M60", "M240"];

  function onChangeDateFrom(value, dateStringFrom) {
    let dateFrom = dateStringFrom + " " + "00:00:00";
    setDateRangeFrom(dateFrom);
  }
  function onChangeDateTo(value, dateStringTo) {
    let dateTo = dateStringTo + " " + "00:00:00";
    setDateRangeTo(dateTo);
  }
  function disabledDate(current) {
    let dateFrom = "2010-01-08 04:00:00";
    let dateTo = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    return (
      (current && current < moment(dateFrom, "YYYY-MM-DD HH:mm:ss")) ||
      current > moment(dateTo, "YYYY-MM-DD HH:mm:ss")
    );
  }
  useEffect(() => {
    applyFilters();
  }, []);
  const applyFilters = (values) => {
    //return false;
    setLoader(true);
    var analogData = {};
    var digitalData = {};
    if (values) {
      analogData = {
        timeframe: values.timeframe,
        currency: values.currency,
        signal_type: "analog",
        from: moment(values.datefrom).format("YYYY-MM-DD HH:mm:ss"),
        to: moment(values.dateto).format("YYYY-MM-DD HH:mm:ss"),
      };
      digitalData = {
        timeframe: values.timeframe,
        currency: values.currency,
        signal_type: "digital",
        from: moment(values.datefrom).format("YYYY-MM-DD HH:mm:ss"),
        to: moment(values.dateto).format("YYYY-MM-DD HH:mm:ss"),
      };
    } else {
      analogData = {
        timeframe: valuesDefault.timeframe,
        currency: valuesDefault.currency,
        signal_type: "analog",
        from: moment(valuesDefault.datefrom).format("YYYY-MM-DD HH:mm:ss"),
        to: moment(valuesDefault.dateto).format("YYYY-MM-DD HH:mm:ss"),
      };
      digitalData = {
        timeframe: valuesDefault.timeframe,
        currency: valuesDefault.currency,
        signal_type: "digital",
        from: moment(valuesDefault.datefrom).format("YYYY-MM-DD HH:mm:ss"),
        to: moment(valuesDefault.dateto).format("YYYY-MM-DD HH:mm:ss"),
      };
    }
    //return false;

    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_STRENGTH}strength_data`,
      data: analogData,
    })
      .then((analogResponse) => {
        analogChart(analogResponse);
        // setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
      });

    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_STRENGTH}strength_data`,
      data: digitalData,
    })
      .then((digitalResponse) => {
        digitalChart(digitalResponse);
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
      });
  };

  // =========== Analog Chart Start ==============
  const analogChart = (res) => {
    var offset = new Date().getTimezoneOffset();
    anychart.format.outputTimezone(offset);
    const timeframeKey = Object.keys(res.data);
    //console.log("timeframeKey", timeframeKey);
    chartAnalog = anychart.line();
    var chart = anychart.line();

    var scroller = chart.xScroller();
    scroller.enabled(true);

    chart.animation(true);
    chart.crosshair().enabled(true);
    chart.tooltip().positionMode("point");

    var xAxisDate = chart.xAxis().labels();
    xAxisDate.padding(10).rotation(90);
    xAxisDate.fontSize(12);
    xAxisDate.format(function (value) {
      var truncateDate = new Date(value["tickValue"]);
      var options = {
        day: "numeric",
        month: "numeric",
      };
      return truncateDate.toLocaleDateString("en-US", options);
    });

    chart.legend().enabled(true).fontSize(13).padding([0, 0, 10, 0]);
    var legend = chart.legend();
    legend.listen("legendItemClick", function (e) {
      var series = chartDigital.getSeriesAt(e.itemIndex);
      series.enabled(!series.enabled());
    });

    var arraycontainsM15 = timeframeKey.indexOf("M15") > -1;
    var arraycontainsM30 = timeframeKey.indexOf("M30") > -1;
    var arraycontainsM60 = timeframeKey.indexOf("M60") > -1;
    var arraycontainsM240 = timeframeKey.indexOf("M240") > -1;

    if (arraycontainsM15) {
      var dataSetM15 = anychart.data.set(res.data["M15"].data["analog"]);
      for (let i = 0; i < res.data["M15"].data["currencies"].length; i++) {
        //console.log(i);
        let data = res.data["M15"].data["currencies"][i];
        var firstSeriesDataM15 = dataSetM15.mapAs({ x: 0, value: 1 + i });
        var firstSeriesM15 = chart.line(firstSeriesDataM15);
        firstSeriesM15.name("M15-" + data);
        firstSeriesM15.hovered().markers().enabled(true).type("circle").size(4);
        firstSeriesM15.tooltip().position("right").anchor("left-center");
      }
    }
    if (arraycontainsM30) {
      var dataSetM30 = anychart.data.set(res.data["M30"].data["analog"]);
      for (let i = 0; i < res.data["M30"].data["currencies"].length; i++) {
        console.log(i);
        let data = res.data["M30"].data["currencies"][i];
        var firstSeriesDataM30 = dataSetM30.mapAs({ x: 0, value: 1 + i });
        var firstSeriesM30 = chart.stepLine(firstSeriesDataM30);
        firstSeriesM30.name("M30-" + data);
        firstSeriesM30.hovered().markers().enabled(true).type("circle").size(4);
        firstSeriesM30.tooltip().position("right").anchor("left-center");
        firstSeriesM30.stepDirection("forward");
      }
    }
    if (arraycontainsM60) {
      var dataSetM60 = anychart.data.set(res.data["M60"].data["analog"]);
      for (let i = 0; i < res.data["M60"].data["currencies"].length; i++) {
        // console.log(i);
        let data = res.data["M60"].data["currencies"][i];
        var firstSeriesDataM60 = dataSetM60.mapAs({ x: 0, value: 1 + i });
        var firstSeriesM60 = chart.stepLine(firstSeriesDataM60);
        firstSeriesM60.name("M60-" + data);
        firstSeriesM60.hovered().markers().enabled(true).type("circle").size(4);
        firstSeriesM60.tooltip().position("right").anchor("left-center");
        firstSeriesM60.stepDirection("forward");
      }
    }
    if (arraycontainsM240) {
      var dataSetM240 = anychart.data.set(res.data["M240"].data["analog"]);
      for (let i = 0; i < res.data["M240"].data["currencies"].length; i++) {
        //console.log(i);
        let data = res.data["M240"].data["currencies"][i];
        var firstSeriesDataM240 = dataSetM240.mapAs({ x: 0, value: 1 + i });
        var firstSeriesM240 = chart.stepLine(firstSeriesDataM240);
        firstSeriesM240.name("M240-" + data);
        firstSeriesM240
          .hovered()
          .markers()
          .enabled(true)
          .type("circle")
          .size(4);
        firstSeriesM240.tooltip().position("right").anchor("left-center");
        firstSeriesM240.stepDirection("forward");
      }
    }

    const myNode = document.getElementById("analogContainer");
    myNode.innerHTML = "";
    chart.container("analogContainer");

    // initiate chart drawing
    chart.xScroller().listen("scrollerchangefinish", function (e) {
      var startRatio = e.startRatio;
      var endRatio = e.endRatio;
      setStartRatio(startRatio);
      setEndRatio(endRatio);
      var xZoom = chartDigital.xZoom();

      xZoom.setTo(startRatio, endRatio);
    });
    chartAnalog = chart;
    chartAnalog.draw();

    // set container id for the chart
  };
  // =========== Analog Chart End ================

  // =========== Digital Chart End ================
  const digitalChart = (res) => {
    var offset = new Date().getTimezoneOffset();
    anychart.format.outputTimezone(offset);
    const timeframeKey = Object.keys(res.data);
    //console.log("timeframeKey", timeframeKey);
    var chart = anychart.line();
    chartDigital = anychart.line();

    chart.animation(true);
    chart.crosshair().enabled(true).yLabel(false).yStroke(null);
    chart.tooltip().positionMode("point");
    chart.legend().enabled(true).fontSize(13).padding([0, 0, 10, 0]);
    var legend = chart.legend();
    legend.listen("legendItemClick", function (e) {
      var series = chartAnalog.getSeriesAt(e.itemIndex);
      series.enabled(!series.enabled());
    });
    var xAxisDate = chart.xAxis().labels();
    xAxisDate.padding(0).rotation(90);
    xAxisDate.fontSize(12);
    xAxisDate.format(function (value) {
      var truncateDate = new Date(value["tickValue"]);
      var options = {
        day: "numeric",
        month: "numeric",
      };
      return truncateDate.toLocaleDateString("en-US", options);
    });

    var arraycontainsM15 = timeframeKey.indexOf("M15") > -1;
    var arraycontainsM30 = timeframeKey.indexOf("M30") > -1;
    var arraycontainsM60 = timeframeKey.indexOf("M60") > -1;
    var arraycontainsM240 = timeframeKey.indexOf("M240") > -1;

    if (arraycontainsM15) {
      var dataSetM15 = anychart.data.set(res.data["M15"].data["digital"]);
      for (let i = 0; i < res.data["M15"].data["currencies"].length; i++) {
        // console.log(i);
        let data = res.data["M15"].data["currencies"][i];
        var firstSeriesDataM15 = dataSetM15.mapAs({ x: 0, value: 1 + i });
        var firstSeriesM15 = chart.line(firstSeriesDataM15);
        firstSeriesM15.name("M15-" + data);
        firstSeriesM15.hovered().markers().enabled(true).type("circle").size(4);
        firstSeriesM15.tooltip().position("right").anchor("left-center");
      }
    }
    if (arraycontainsM30) {
      var dataSetM30 = anychart.data.set(res.data["M30"].data["digital"]);
      for (let i = 0; i < res.data["M30"].data["currencies"].length; i++) {
        //  console.log(i);
        let data = res.data["M30"].data["currencies"][i];
        var firstSeriesDataM30 = dataSetM30.mapAs({ x: 0, value: 1 + i });
        var firstSeriesM30 = chart.stepLine(firstSeriesDataM30);
        firstSeriesM30.name("M30-" + data);
        firstSeriesM30.hovered().markers().enabled(true).type("circle").size(4);
        firstSeriesM30.tooltip().position("right").anchor("left-center");
        firstSeriesM30.stepDirection("forward");
      }
    }
    if (arraycontainsM60) {
      var dataSetM60 = anychart.data.set(res.data["M60"].data["digital"]);
      for (let i = 0; i < res.data["M60"].data["currencies"].length; i++) {
        //  console.log(i);
        let data = res.data["M60"].data["currencies"][i];
        var firstSeriesDataM60 = dataSetM60.mapAs({ x: 0, value: 1 + i });
        var firstSeriesM60 = chart.stepLine(firstSeriesDataM60);
        firstSeriesM60.name("M60-" + data);
        firstSeriesM60.hovered().markers().enabled(true).type("circle").size(4);
        firstSeriesM60.tooltip().position("right").anchor("left-center");
        firstSeriesM60.stepDirection("forward");
      }
    }
    if (arraycontainsM240) {
      var dataSetM240 = anychart.data.set(res.data["M240"].data["digital"]);
      for (let i = 0; i < res.data["M240"].data["currencies"].length; i++) {
        // console.log(i);
        let data = res.data["M240"].data["currencies"][i];
        var firstSeriesDataM240 = dataSetM240.mapAs({ x: 0, value: 1 + i });
        var firstSeriesM240 = chart.stepLine(firstSeriesDataM240);
        firstSeriesM240.name("M240-" + data);
        firstSeriesM240
          .hovered()
          .markers()
          .enabled(true)
          .type("circle")
          .size(4);
        firstSeriesM240.tooltip().position("right").anchor("left-center");
        firstSeriesM240.stepDirection("forward");
      }
    }

    const myNode = document.getElementById("digitalContainer");
    myNode.innerHTML = "";
    chart.container("digitalContainer");
    // initiate chart drawing

    chartDigital = chart;
    chartDigital.draw();
    setOpenFilter(false);

    // set container id for the chart
  };

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="economic-events">
          <div className="page-title cursor-pointer" onClick={showFilter}>
            <h1>Strength</h1>
            <MoreOutlined />
          </div>

          <div className="card">
            <div className="card-body">
              <span className="ribbon4">Analog</span>
              <div id="analogContainer" className="half-container"></div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <span className="ribbon4">Digital</span>
              <div id="digitalContainer" className="half-container"></div>
              {loader ? (
                <div className="loader">
                  <div className="linespinner"></div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <Drawer
            title="Filters"
            placement="right"
            width={720}
            onClose={onCloseFilter}
            open={openFilter}
          >
            <Form
              name="horizontal_fitlers"
              layout="vertical"
              onFinish={applyFilters}
              initialValues={valuesDefault}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label="From" name="datefrom">
                    <DatePicker
                      disabledDate={disabledDate}
                      onChange={onChangeDateFrom}
                      size="large"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="To" name="dateto">
                    <DatePicker
                      disabledDate={disabledDate}
                      onChange={onChangeDateTo}
                      size="large"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <b>Currency: &nbsp;&nbsp;</b>
              <Form.Item name="currency">
                <Checkbox.Group options={currencyOptions} />
              </Form.Item>

              <div className="currency-checkboxes">
                <b>Timeframe: &nbsp;&nbsp;</b>
                <Form.Item name="timeframe">
                  <Checkbox.Group options={timeframeOptions} />
                </Form.Item>
                <Form.Item name="signal_type" style={{ display: "none" }}>
                  <Input type="text" />
                </Form.Item>
              </div>

              <Button type="primary" className="btn-theme" htmlType="submit">
                Apply Filters
              </Button>
            </Form>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default Strength;
