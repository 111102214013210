import React from "react";
import logo from "../assets/images/logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../utils/auth";
import {
  BarChartOutlined,
  SlidersOutlined,
  FundOutlined,
  LogoutOutlined,
  NotificationOutlined,
  SnippetsOutlined,
  RiseOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
const Sidebar = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const logout = () => {
    auth.logout();
    localStorage.removeItem("user");
    navigate("/login");
  };
  return (
    <aside className="sidebar">
      <div className="side-header">
        <Link className="brand" to="/">
          <img width="30" src={logo} className="desktop-logo" alt="logo" />
        </Link>
      </div>
      <nav className="side-nav">
        <ul>
          <li className={splitLocation[1] === "Home" ? "active" : ""}>
            <Tooltip placement="rightTop" title="Home">
              <Link to="/Home">
                <HomeOutlined />
              </Link>
            </Tooltip>
          </li>
          <li className={splitLocation[1] === "EconomicEvent" ? "active" : ""}>
            <Tooltip placement="rightTop" title="Economic Events">
              <Link to="/EconomicEvent">
                <BarChartOutlined />
              </Link>
            </Tooltip>
          </li>
          <li className={splitLocation[1] === "Indicators" ? "active" : ""}>
            <Tooltip placement="rightTop" title="Technical Indicators">
              <Link to="/Indicators">
                <SlidersOutlined />
              </Link>
            </Tooltip>
          </li>
          {/* <li className={splitLocation[1] === "Strength" ? "active" : ""}>
            <Tooltip placement="rightTop" title="Strength">
              <Link to="/Strength">
                <FundOutlined />
              </Link>
            </Tooltip>
          </li> */}
          <li className={splitLocation[1] === "" ? "active" : ""}>
            <Tooltip placement="rightTop" title="Trading">
              <Link to="/Trading">
                <NotificationOutlined />
              </Link>
            </Tooltip>
          </li>
          <li className={splitLocation[1] === "" ? "active" : ""}>
            <Tooltip placement="rightTop" title="BackTesting">
              <Link to="/model-results">
                <SnippetsOutlined />
              </Link>
            </Tooltip>
          </li>
          <li className={splitLocation[1] === "" ? "active" : ""}>
            <Tooltip placement="rightTop" title="Live Predictions">
              <Link to="/live-chart">
                <RiseOutlined />
              </Link>
            </Tooltip>
          </li>
        </ul>

        <div className="logout" onClick={logout}>
          <Tooltip placement="rightTop" title="Logout">
            <LogoutOutlined />
          </Tooltip>
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;
