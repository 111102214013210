export const markers = [
  { name: "triangle-up", label: "triangle-up" },
  { name: "triangle-down", label: "triangle-down" },
  { name: "star5", label: "star" },
  { name: "circle", label: "circle" },
  { name: "cross", label: "plus" },
  { name: "arrow-down", label: "arrow-down" },
  { name: "arrow-up", label: "arrow-up" },
  { name: "diagonal-cross", label: "cross" },
  { name: "diamond", label: "diamond" },
  { name: "pentagon", label: "pentagon" },
  { name: "square", label: "square" },
];

export const colors = [
  "red",
  "green",
  "blue",
  "yellow",
  "orange",
  "purple",
  "pink",
  "brown",
  "cyan",
  "magenta",
  "teal",
  "lime",
  "indigo",
  "maroon",
  "navy",
  "olive",
  "silver",
  "gold",
  "violet",
  "turquoise",
  "aquamarine",
  "coral",
  "crimson",
  "darkgreen",
  "darkorange",
  "fuchsia",
  "lightblue",
  "lightgreen",
  "orchid",
  "palevioletred",
  "sienna",
  "steelblue",
  "aliceblue",
  "antiquewhite",
  "azure",
  "beige",
  "cadetblue",
  "chocolate",
  "cornflowerblue",
  "darkcyan",
  "darkorchid",
  "deeppink",
  "firebrick",
  "forestgreen",
  "gainsboro",
  "honeydew",
  "indianred",
  "khaki",
  "lavender",
  "lightcoral",
  "mediumaquamarine",
  "midnightblue",
  "navajowhite",
  "oldlace",
  "papayawhip",
  "peru",
  "rosybrown",
  "seagreen",
  "slategray",
  "tomato",
];
