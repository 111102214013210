import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../utils/auth";
import { v4 as uuid } from "uuid";
import { notification } from "antd";
import axios from "axios";
const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    document.title = "Login";
  }, []);

  const loginUser = async (event) => {
    event.preventDefault();
    setLoader(true);
    const username = event.target.username.value;
    const password = event.target.password.value;
    const unique_id = uuid();

    const headers = {
      "Content-Type": "application/json",
      uuid: "de305d54-75b4-431b-adb2-eb6b9e546013",
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ECONOMIC}auth/User_Login/`,
        {
          username: username,
          password: password,
          fcm_token:
            "cCIfnJohThCEBSds-Bx5QQ:APA91bGlOBjZGS7-awws3yKkL_YfEkHqseZ24Ye4vguAaHmIj2YOYy6Ecu4uUGhvJ2LGk0lvu6DOvh8XQ7jrxjQMawbzbgkB8xjwzVDQq4yghwYYr9MGerX54Vvfe_boEMbQkFRfeTbb",
        },
        {
          headers: headers,
        }
      );

      if (response.status === 200) {
        const result = response.data;

        notification.success({
          message: "User logged in successfully",
        });

        localStorage.setItem("user", unique_id);
        localStorage.setItem("token", result.data.authorized_token);
        auth.login(result.data.authorized_token);
        navigate("/", { replace: true });
        setError(false);
        setLoader(false);
      } else {
        console.log("Else Login failed");
        setError(true);
        setLoader(false);
      }
    } catch (error) {
      console.log("error", error);
      setError(true);
      setLoader(false);
    }
  };
  return (
    <div className="fullscreen bg-black">
      <div className="login-wrap">
        <h1>Sign in</h1>
        <form onSubmit={loginUser}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input type="text" id="username" required />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" required />
          </div>
          <div className="form-group">
            <button>{loader ? "Loging..." : "Sign in"}</button>
          </div>
          {error && <div className="error">Username or password incorrect</div>}
        </form>
      </div>
    </div>
  );
};

export default Login;
