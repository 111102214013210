import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Sidebar from "../components/Sidebar";
import {
  Form,
  Button,
  Select,
  DatePicker,
  Checkbox,
  Row,
  Col,
  InputNumber,
  Collapse,
  Tabs,
} from "antd";
const dateFormat = "YYYY-MM-DD";
const { Option } = Select;
const { Panel } = Collapse;

const valuesDefault = {
  currencies: ["EUR_USD"],
  timeframe: "M60",
  from: moment(new Date()).subtract(2, "months"),
  to: moment(new Date()),
  sell_success_gain_pips: -50,
  sell_failure_gain_pips: 20,
  buy_success_gain_pips: 50,
  buy_failure_gain_pips: -20,
  num_candles: 4,
  tolerance_level: 5,
  type: "Medium",
  before_candles: 10,
  after_candles: 10,
};

const Analysis = () => {
  const [loader, setLoader] = useState(false);
  const [detailReport, setDetailReport] = useState(false);
  const [data, setData] = useState(null);
  const [path, setPath] = useState(null);
  const [dateRangeFrom, setDateRangeFrom] = useState("");
  const [dateRangeTo, setDateRangeTo] = useState("");

  function onChangeDateFrom(value, dateStringFrom) {
    let dateFrom = dateStringFrom + " " + "00:00:00";
    setDateRangeFrom(dateFrom);
  }
  function onChangeDateTo(value, dateStringTo) {
    let dateTo = dateStringTo + " " + "00:00:00";
    setDateRangeTo(dateTo);
  }
  function disabledDate(current) {
    let dateFrom = "2010-01-08 04:00:00";
    let dateTo = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    return (
      (current && current < moment(dateFrom, "YYYY-MM-DD HH:mm:ss")) ||
      current > moment(dateTo, "YYYY-MM-DD HH:mm:ss")
    );
  }
  const onFinish = (values) => {
    setLoader(true);
    var startTime = new Date();
    var formData = {};
    formData = {
      currencies: values.currencies,
      timeframe: values.timeframe,
      from: moment(values.from).format("YYYY-MM-DD HH:mm:ss"),
      to: moment(values.to).format("YYYY-MM-DD HH:mm:ss"),
      sell: {
        success: {
          gain_pips: values.sell_success_gain_pips,
        },
        failed: {
          gain_pips: values.sell_failure_gain_pips,
        },
      },
      buy: {
        success: {
          gain_pips: values.buy_success_gain_pips || 50,
        },
        failed: {
          gain_pips: values.buy_failure_gain_pips || -20,
        },
      },
      trading_strategy: {
        trading_strategy_A: {
          flat_candles: {
            status: true,
            num_candles: values.num_candles,
            tolerance_level: values.tolerance_level,
          },
          moving_average: {
            status: true || true,
            type: values.type || "Medium",
            before_candles: values.before_candles || 10,
            after_candles: values.after_candles || 10,
          },
        },
      },
      detail_report: detailReport,
    };
    // setTimeout(() => {
    //   setLoader(false);
    //   var endTime = new Date();
    //   const request_duration = endTime - startTime;

    //   console.log("time", request_duration);
    // }, 8000);
    console.log("formData", formData);
    setData(null);
    setPath(null);

    axios({
      method: "post",
      headers: { "Content-Type": "application/json" },
      url: `${process.env.REACT_APP_CURRENCY_STRENGTH}pattern_detection`,
      data: formData,
    })
      .then((res) => {
        console.log("res", res.data.data);
        setData(res.data.data);
        setPath(res.data.path);
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
      });
  };

  const downloadDetailResult = () => {
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_CURRENCY_STRENGTH}detail_report?path=${path}`,

        { responseType: "blob" }
      )
      .then(({ data: blob }) => {
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        console.log(url);
        link.href = url;
        link.download = "DetailResults.zip";
        link.click();
        setLoader(false);
      });
  };
  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="economic-events analysis">
          <div className="page-title">
            <h1>Analysis</h1>
          </div>

          <div className="card">
            <div className="card-body">
              <Form
                name="horizontal_login"
                onFinish={onFinish}
                initialValues={valuesDefault}
                layout="vertical"
              >
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item label="From" name="from">
                      <DatePicker
                        format={dateFormat}
                        disabledDate={disabledDate}
                        onChange={onChangeDateFrom}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="To" name="to">
                      <DatePicker
                        format={dateFormat}
                        disabledDate={disabledDate}
                        onChange={onChangeDateTo}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item label="Currencies" name="currencies">
                      <Select
                        mode="multiple"
                        placeholder="Select"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: "100%" }}
                      >
                        <Option value="EUR_USD">EUR_USD</Option>
                        <Option value="EUR_JPY">EUR_JPY</Option>
                        <Option value="USD_JPY">USD_JPY</Option>
                        <Option value="CHF_JPY">CHF_JPY</Option>
                        <Option value="CAD_JPY">CAD_JPY</Option>
                        <Option value="AUD_USD">AUD_USD</Option>
                        <Option value="AUD_JPY">AUD_JPY</Option>
                        <Option value="GBP_JPY">GBP_JPY</Option>
                        <Option value="GBP_USD">GBP_USD</Option>
                        <Option value="NZD_JPY">NZD_JPY</Option>
                        <Option value="NZD_USD">NZD_USD</Option>
                        <Option value="USD_CAD">USD_CAD</Option>
                        <Option value="USD_CHF">USD_CHF</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Time Frame" name="timeframe">
                      <Select style={{ width: "100%" }}>
                        <Option value="M5">M5</Option>
                        <Option value="M10">M10</Option>
                        <Option value="M15">M15</Option>
                        <Option value="M30">M30</Option>
                        <Option value="M60">M60</Option>
                        <Option value="M240">M240</Option>
                        <Option value="M1440">M1440</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <div className="title-box">
                      <h3>Sell</h3>
                      <div className="sell-box">
                        <Row gutter={8}>
                          <Col span={6}>
                            <Form.Item
                              label="Gain Pips For Success"
                              name="sell_success_gain_pips"
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Form.Item
                              label="Loss Pips For Failed"
                              name="sell_failure_gain_pips"
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="title-box">
                      <h3>Buy</h3>
                      <div className="buy-box">
                        <Row gutter={8}>
                          <Col span={6}>
                            <Form.Item
                              label="Gain Pips For Success"
                              name="buy_success_gain_pips"
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>

                          <Col span={6}>
                            <Form.Item
                              label="Loss Pips For Failed"
                              name="buy_failure_gain_pips"
                            >
                              <InputNumber style={{ width: "100%" }} />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Collapse defaultActiveKey={["1"]}>
                  <Panel header="Trading Strategy A" key="1">
                    <Tabs type="card" defaultActiveKey="1">
                      <Tabs.TabPane tab="Flat Candles" key="1">
                        <div>
                          <Row gutter={16}>
                            <Col span={4}>
                              <Form.Item
                                label="No of candles"
                                name="num_candles"
                              >
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item
                                label="Tolerance level"
                                name="tolerance_level"
                              >
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Tabs.TabPane>
                      <Tabs.TabPane tab="Moving Average" key="2">
                        <div>
                          <Row gutter={16}>
                            <Col span={6}>
                              <Form.Item label="Type" name="type">
                                <Select style={{ width: "100%" }}>
                                  <Option value="Slow">Slow</Option>
                                  <Option value="Medium">Medium</Option>
                                  <Option value="Fast">Fast</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label="Number of candles before flat candle"
                                name="before_candles"
                              >
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label="Number of candles after flat candle"
                                name="after_candles"
                              >
                                <InputNumber style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </Tabs.TabPane>
                    </Tabs>
                  </Panel>
                </Collapse>
                <br /> <br />
                <Row gutter={16}>
                  <Col span={24} className="text-right">
                    <div className="btn-group-analysis">
                      <Button
                        type="primary"
                        className="btn-theme"
                        htmlType="submit"
                        onClick={() => setDetailReport(false)}
                      >
                        Generate Summary
                      </Button>
                      <Button
                        type="primary"
                        className="btn-theme"
                        htmlType="submit"
                        onClick={() => setDetailReport(true)}
                      >
                        Generate Detail Results
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="text-right">
                {path ? (
                  <Button
                    type="primary"
                    className="btn-theme"
                    onClick={downloadDetailResult}
                  >
                    Download Detail Results
                  </Button>
                ) : null}
              </div>

              <table className="table-analysis">
                <thead>
                  <tr>
                    <th>Currency</th>
                    <th>Success</th>
                    <th>Failure</th>
                    <th>Pips Gained</th>
                    <th>Pips Lost</th>
                    <th>Net Profit</th>
                  </tr>
                </thead>

                <tbody>
                  {data &&
                    data.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <span>{item.currency}</span>
                          </td>
                          <td>{item.summary.Total_success}</td>
                          <td>{item.summary.Total_failed}</td>
                          <td>{item.summary.Total_pips_gained}</td>
                          <td>{item.summary.Total_pips_lost}</td>
                          <td>{item.summary.Net_profit}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {data == null ? (
                <div className="table-nodata">No data found!</div>
              ) : (
                ""
              )}
            </div>
          </div>

          {loader ? (
            <div className="loader">
              <div className="linespinner"></div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default Analysis;
