import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Indicators from "../pages/Indicators";
import Strength from "../pages/Strength";
import EconomicEvent from "../pages/EconomicEvents";
import Analysis from "../pages/Analysis";
import Login from "../pages/Login";
import RequireAuth from "./RequireAuth";
import LiveData from "../pages/LiveData";
import { ModelResults } from "../pages/ModelResults";
import { LiveChart } from "../pages/LiveChart";
import Comparison from "../pages/Comparisons";

const Routing = () => {
  return (
    <Routes>
      <Route exact path="/login" element={<Login />} />

      <Route
        path="/"
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      <Route
        path="/Home"
        element={
          <RequireAuth>
            <Home />
          </RequireAuth>
        }
      />
      <Route
        path="/EconomicEvent"
        element={
          <RequireAuth>
            <EconomicEvent />
          </RequireAuth>
        }
      />
      <Route
        path="/Indicators"
        element={
          <RequireAuth>
            <Indicators />
          </RequireAuth>
        }
      />
      <Route
        path="/Trading"
        element={
          <RequireAuth>
            <LiveData />
          </RequireAuth>
        }
      />
      <Route
        path="/Analysis"
        element={
          <RequireAuth>
            <Analysis />
          </RequireAuth>
        }
      />
      <Route
        path="/Strength"
        element={
          <RequireAuth>
            <Strength />
          </RequireAuth>
        }
      />

      <Route
        path="/model-results"
        element={
          <RequireAuth>
            <ModelResults />
          </RequireAuth>
        }
      />
      <Route
        path="/live-chart"
        element={
          <RequireAuth>
            <LiveChart />
          </RequireAuth>
        }
      />
      <Route
        path="/comparison"
        element={
          <RequireAuth>
            <Comparison />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default Routing;
