import React, { useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { Link, useLocation, useNavigate } from "react-router-dom";
const Home = () => {
  useEffect(() => {
    document.title = "Home";
  }, []);
  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="homepage">
          <h1>Quick Links</h1>
          <div className="quick-links">
            <Link to="/EconomicEvent">Economic Event</Link>
            <Link to="/Indicators">Technical Indicators</Link>
            <Link to="/Trading">Trading</Link>
            <Link to="/model-results">
              BackTesting
              <span>
                <span className="csv">CSV</span>
              </span>
            </Link>
            <Link to="/live-chart">
              Live Predictions
              <span>
                <span className="live">Live</span>
              </span>
            </Link>
            {/* <Link to="/comparison">Comparison</Link> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
