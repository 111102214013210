import React, { useState, useEffect } from "react";
import { MoreOutlined } from "@ant-design/icons";
import EconomicEvent from "../components/EconomicEvent";
import OHLC from "../components/OHLC";
import axios from "axios";

import {
  Col,
  Row,
  Drawer,
  Form,
  DatePicker,
  Select,
  Radio,
  Button,
  notification,
} from "antd";
import moment from "moment";
import Sidebar from "../components/Sidebar";
import api from "../utils/api";

const { Option, OptGroup } = Select;
const dateFormat = "YYYY-MM-DD";
const valuesDefault = {
  from_date: moment(new Date()).subtract(12, "months"),
  to_date: moment(new Date()),
  currency: "USD",
  event: "CPI y/y",
  currency_pair: "USD/JPY",
  impact: "High",
};
function EconomicEvents() {
  const [openFilter, setOpenFilter] = useState(false);
  const [loader, setLoader] = useState(false);
  const [schema, setSchema] = useState([]);
  const [selectedPair, setSelectedPair] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState(null);
  const [economicData, setEconomicData] = useState(null);
  const [ohlcData, setOhlcData] = useState(null);
  const [ohlcGraph, setOhlcGraph] = useState(null);
  const [activeTimeframe, setActiveTimeframe] = useState("M15");
  const [dateFromChange, setDateFromChange] = useState("2020-01-01");
  const [dateToChange, setDateToChange] = useState("2022-09-26");
  const [activeDate, setActiveDate] = useState(null);

  function disabledDate(current) {
    let dateFrom = "2010-01-03 17:00:00";
    let dateTo = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    // console.log("current", current);
    return (
      (current && current < moment(dateFrom, "YYYY-MM-DD HH:mm:ss")) ||
      current > moment(dateTo, "YYYY-MM-DD HH:mm:ss")
    );
  }
  function onChangeDateFrom(value, dateStringFrom) {
    setDateFromChange(dateStringFrom);

    if (
      moment(dateStringFrom, "YYYY-MM-DD HH:mm:ss") >
      moment(dateToChange, "YYYY-MM-DD HH:mm:ss")
    ) {
      console.log("greater");
      notification["error"]({
        message: "Date from should be in the past",
      });
    }
  }
  function onChangeDateTo(value, dateStringTo) {
    setDateToChange(dateStringTo);

    if (
      moment(dateStringTo, "YYYY-MM-DD HH:mm:ss") <
      moment(dateFromChange, "YYYY-MM-DD HH:mm:ss")
    ) {
      console.log("greater");
      notification["error"]({
        message: "Date from should be in the future",
      });
    }
  }

  const showFilter = () => {
    setOpenFilter(true);
  };
  const onCloseFilter = () => {
    setOpenFilter(false);
  };
  const onFilterSubmit = (values) => {
    getEconomicEvent(values);
  };
  useEffect(() => {
    getSchema();
    getEconomicEvent();
    document.title = "Economic Event";
  }, []);
  const getSchema = () => {
    api({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}`,
    })
      .then((res) => {
        if (res.status === 200) {
          setSchema(res.data);

          setSelectedPair(res.data[0].currency_pairs);
          setSelectedEvents(res.data[0].economic_events[0]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const getEconomicEvent = (values) => {
    setLoader(true);
    var from_date = moment(valuesDefault.from_date).format("YYYY-MM-DD");
    var to_date = moment(valuesDefault.to_date).format("YYYY-MM-DD");
    var currency = valuesDefault.currency;
    var event = valuesDefault.event;

    var currency_pair = valuesDefault.currency_pair;
    var impact = valuesDefault.impact;

    localStorage.setItem("lastCurrencyPair", "USD/JPY");
    if (values) {
      from_date = moment(values.from_date).format("YYYY-MM-DD");
      to_date = moment(values.to_date).format("YYYY-MM-DD");

      currency = values.currency;
      let eventArray = values.event.split(",");
      event = eventArray[0];
      impact = eventArray[1] ? eventArray[1] : "High";
      currency_pair = values.currency_pair;
      localStorage.setItem("lastCurrencyPair", values.currency_pair);
      setOpenFilter(false);
      console.log("valuesss", values);
    }

    api({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}economic_event/?currency=${currency}&from_date=${from_date}&to_date=${to_date}&event=${event}&impact=${impact}`,
    })
      .then((res) => {
        if (res.status === 200) {
          setLoader(false);
          setEconomicData(res.data[0]);
          if (res.data.length > 0) {
            let last = res.data[0].event_data.slice(-1);
            getOHLC(last[0].date + " " + last[0].time, currency_pair);
          } else {
            setOhlcGraph([]);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
      });
  };
  const getOHLC = (selectedDate, changePair) => {
    setLoader(true);
    setActiveDate(
      moment(selectedDate, "YYYY-MM-DD hh:mm:ss A").format(
        "YYYY-MM-DD HH:mm:ss"
      )
    );
    const date = selectedDate;
    const pair = changePair ? changePair : "USD/JPY";

    api({
      method: "GET",
      url: `${process.env.REACT_APP_BASE_URL}/ohlc/?currency_pair=${pair}&date=${date}`,
    })
      .then((res) => {
        if (res.status === 200) {
          setOhlcData(res.data);
          setOhlcGraph(res.data[0]);
          setActiveTimeframe("M5");
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoader(false);
      });
  };

  // Events =========================================================================
  const onChangeCurrency = (e) => {
    setSelectedEvents(null);
    let filterSchema = schema.find((c) => c.currency === e.target.value);
    if (filterSchema) {
      setSelectedPair(filterSchema.currency_pairs);
      setSelectedEvents(filterSchema.economic_events[0]);
    }
  };

  // const onChangeEvent = (e) => {
  //   setActiveImpact(e.title);
  //   console.log(e);
  // };
  const changeTimeframe = (timeframe) => {
    setOhlcGraph(null);
    let filterOhlc = ohlcData.find((t) => t.timeframe === timeframe);
    setOhlcGraph(filterOhlc);
    setActiveTimeframe(timeframe);
  };
  // function filterOptions(input, option) {
  //   let value = input.toLowerCase();
  //   console.log(option);
  // }

  // Events End =====================================================================

  return (
    <>
      <Sidebar />
      <div className="main-content">
        <div className="economic-events">
          <Row gutter={16}>
            <Col span={4}>
              <div className="page-title cursor-pointer" onClick={showFilter}>
                <h1>Economic Events</h1>
                <MoreOutlined />
              </div>
            </Col>
          </Row>
          <EconomicEvent
            data={economicData}
            showFilter={showFilter}
            getOHLC={getOHLC}
          />
          <OHLC
            data={ohlcGraph}
            changeTimeframe={changeTimeframe}
            activeTimeframe={activeTimeframe}
            activeDate={activeDate}
          />

          <Drawer
            title="Filters"
            placement="right"
            width={720}
            onClose={onCloseFilter}
            open={openFilter}
          >
            {schema ? (
              <Form
                layout="vertical"
                onFinish={onFilterSubmit}
                initialValues={valuesDefault}
              >
                <div className="light-box">
                  <h3>Select Date</h3>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label="From"
                        name="from_date"
                        rules={[
                          {
                            required: true,
                            message: "Please input date from",
                          },
                        ]}
                      >
                        <DatePicker
                          format={dateFormat}
                          size="large"
                          disabledDate={disabledDate}
                          onChange={onChangeDateFrom}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="To"
                        name="to_date"
                        rules={[
                          {
                            required: true,
                            message: "Please input to date",
                          },
                        ]}
                      >
                        <DatePicker
                          format={dateFormat}
                          size="large"
                          disabledDate={disabledDate}
                          onChange={onChangeDateTo}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="light-box">
                  <h3>Select Currency</h3>
                  <Form.Item label="currencies" name="currency">
                    <Radio.Group onChange={onChangeCurrency}>
                      {schema.map((c, i) => (
                        <Radio.Button key={i} value={c.currency}>
                          {c.currency}
                        </Radio.Button>
                      ))}
                      ;
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item label="Currency Pair" name="currency_pair">
                    <Radio.Group>
                      {selectedPair &&
                        selectedPair.map((pair, i) => (
                          <Radio.Button key={i} value={pair}>
                            {pair}
                          </Radio.Button>
                        ))}
                      ;
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className="light-box">
                  <h3>Select Economic Event</h3>
                  {selectedEvents ? (
                    <Form.Item name="event">
                      <Select
                        size="large"
                        style={{ width: "100%" }}
                        showSearch
                        filterOption={(input, option) =>
                          (option?.value ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        <OptGroup label="High impact">
                          {selectedEvents.high.map((val, i) => (
                            <Option key={val + i++} value={val + "," + "High"}>
                              {val}
                            </Option>
                          ))}
                          ;
                        </OptGroup>
                        <OptGroup label="Medium impact">
                          {selectedEvents.medium.map((val, key) => (
                            <Option key={key + 1} value={val + "," + "Medium"}>
                              {val}
                            </Option>
                          ))}
                          ;
                        </OptGroup>
                      </Select>
                    </Form.Item>
                  ) : (
                    ""
                  )}
                </div>
                <Button type="primary" className="btn-theme" htmlType="submit">
                  Submit
                </Button>
              </Form>
            ) : (
              ""
            )}
          </Drawer>
        </div>
      </div>
      {loader ? (
        <div className="loader">
          <div className="linespinner"></div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default EconomicEvents;
