import React, { useState, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { Row, Col, Select, Spin, Button } from "antd";
import anychart from "anychart";
export const LiveChart = () => {
  const [loader, setLoader] = useState(false);
  const [activeRow, setActiveRow] = useState(null);
  const [dataSet, setDataSet] = useState([]);

  const { Option } = Select;
  const socketURL =
    "wss://luccro.infosiphon.com/economic_data/model_inference/candles/";
  useEffect(() => {
    callWebSocket();
    document.title = "Live Predictions";
  }, []);
  const callWebSocket = () => {
    setLoader(true);
    const conn = new WebSocket(socketURL);

    conn.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response) {
        setDataSet(response);
        setLoader(false);
        setActiveRow(null);
      }
    };
    return () => {
      conn.close();
    };
  };

  const showChart = (data, model, index, outputs) => {
    createChart(data, model, outputs);
    setActiveRow(null);
    setActiveRow(model + index);
  };
  const createChart = (data, model, outputs) => {
    let offset = new Date().getTimezoneOffset();
    anychart.format.outputTimezone(offset);
    let dataTable = anychart.data.table();
    let chart = anychart.stock(true);
    let plot = chart.plot(0);
    let anno = plot.annotations();

    for (const item of data) {
      let candleDataActual = [
        item["datetime"],
        item["open"],
        item["high"],
        item["low"],
        item["close"],
        item["MA"] ? item["MA"]["actual"] : null,
        item["MA"] ? item["MA"]["predicted"] : null,
        item["CASES"]["CASE_1"],
        item["CASES"]["CASE_2"],
        item["DIR"]["actual"],
        item["DIR"]["predicted"],
      ];

      dataTable.addData([candleDataActual]);

      if (item["CASES"]["CASE_2"] !== null) {
        if (item["CASES"]["CASE_2"] === 1) {
          anno
            .marker()
            .xAnchor(item["datetime"])
            .valueAnchor(item["high"])
            .fill("green")
            .stroke("0.5 green 0.75")
            .markerType("star5")
            .size(10)
            .allowEdit(false);
        }
        if (item["CASES"]["CASE_2"] === 0) {
          anno
            .marker()
            .xAnchor(item["datetime"])
            .valueAnchor(item["low"])
            .fill("red")
            .stroke("0.5 red 0.75")
            .markerType("diagonal-cross")
            .size(10)
            .allowEdit(false);
        }
      }
    }

    let mapping = dataTable.mapAs();
    mapping.addField("datetime", 0, "datetime");
    mapping.addField("open", 1, "open");
    mapping.addField("high", 2, "high");
    mapping.addField("low", 3, "low");
    mapping.addField("close", 4, "close");
    mapping.addField("MA_ACTUAL", 5, "MA_ACTUAL");
    mapping.addField("MA_PREDICTED", 6, "MA_PREDICTED");
    mapping.addField("CASE_1", 7, "CASE_1");
    mapping.addField("CASE_2", 8, "CASE_2");
    mapping.addField("DIR_ACTUAL", 9, "DIR_ACTUAL");
    mapping.addField("DIR_PREDICTED", 10, "DIR_PREDICTED");

    chart.scroller().xAxis(false);
    let tooltip = chart.tooltip();
    tooltip.background().fill("#2c2c2c");
    tooltip.background().stroke("#2c2c2c");
    tooltip.fontColor("#fff");

    let grouping = chart.grouping();
    grouping.enabled(false);
    chart.crosshair().displayMode("float");
    let crosshair = chart.crosshair();
    crosshair.enabled(true);
    crosshair.displayMode("float");

    plot.height("100%");
    let bottomLines = chart.plot(1);
    bottomLines.height("0");

    chart
      .crosshair()
      .yLabel()
      .format(function () {
        return this.rawValue.toFixed(5);
      })
      .offsetX(-80);

    plot.legend().title(false);

    plot.candlestick(mapping).name("OHLC");
    plot
      .line(dataTable.mapAs({ value: 5 }))
      .stroke("1 #101823")
      .name("MA")
      .enabled(true);
    plot
      .line(dataTable.mapAs({ value: 6 }))
      .stroke({
        color: "#ff7f50",
        dash: "3 5",
      })
      .name("MA_PREDICTED")
      .enabled(true);

    let case1 = dataTable.mapAs({ value: 7 });
    let case1Line = chart.plot(1).line(case1);
    case1Line.name("Case 1");
    case1Line.stroke("#013179 0.9");
    case1Line.enabled(true);

    let case2 = dataTable.mapAs({ value: 8 });
    let case2Line = chart.plot(1).line(case2);
    case2Line.name("Case 2");
    case2Line.stroke("#013179 0.9");
    case2Line.enabled(true);

    let direActual = dataTable.mapAs({ value: 9 });
    let direActualLine = chart.plot(1).line(direActual);
    direActualLine.name("DIR_ACTUAL");
    direActualLine.stroke("green 0.9");
    direActualLine.enabled(true);

    let direPredicted = dataTable.mapAs({ value: 10 });
    let direPredictedLine = chart.plot(1).line(direPredicted);
    direPredictedLine.name("DIR_PREDICTED");
    direPredictedLine.stroke("red 0.9");
    direPredictedLine.enabled(true);

    plot.yGrid(false).yMinorGrid(false);

    const myNode = document.getElementById("container");
    myNode.innerHTML = "";
    if (!chart.container()) chart.container("container");
    chart.draw();

    let rangePicker = anychart.ui.rangePicker();
    rangePicker.render(chart);
    let rangeSelector = anychart.ui.rangeSelector();
    rangeSelector.render(chart);
  };
  return (
    <>
      <Sidebar />
      <div className="main-content">
        <Spin spinning={loader} tip="Fetching Live Forecast...">
          <div className="card">
            <div className="card-header">
              <Row gutter={16} align="middle">
                <Col>
                  <div className="header-title">Live Forecast</div>
                </Col>
              </Row>
            </div>
            <div className="card-body  p-20">
              {dataSet && dataSet.length > 0 ? (
                <div>
                  <div className="table-responsive tableFixHead">
                    <table className="custom-table nolinks">
                      <thead>
                        <tr>
                          <th>Model</th>
                          <th>Currency</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataSet.map((item, index) => (
                          <tr key={index}>
                            <td>{item.model}</td>
                            <td>
                              {item.data.map((c, idx) => {
                                const currency = c.currency_pair;
                                return (
                                  <React.Fragment key={idx}>
                                    <a
                                      onClick={() =>
                                        showChart(
                                          c.results,
                                          item.model,
                                          idx,
                                          c.outputs
                                        )
                                      }
                                      type="link"
                                      className={
                                        activeRow === item.model + idx
                                          ? "btn-active"
                                          : ""
                                      }
                                      size="small"
                                    >
                                      {currency}
                                    </a>
                                    {idx !== item.data.length - 1 ? ", " : ""}
                                  </React.Fragment>
                                );
                              })}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div
                    style={{
                      height: "calc(100vh - 20vh)",
                    }}
                    id="container"
                  ></div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Spin>
      </div>
    </>
  );
};
