import React, { useState } from "react";
import Chart from "react-apexcharts";
import moment from "moment";
const OHLC = (props) => {
  var dataArray = [];
  if (props.data && props.data.data) {
    props.data.data.forEach((element) => {
      dataArray.push({
        x: element.datetime,
        y: [element.open, element.high, element.low, element.close],
      });
    });
    var maxValue = Math.max(...props.data.data.map((o) => o.high));
  }
  const series = [
    {
      data: dataArray,
    },
  ];

  const options = {
    chart: {
      type: "candlestick",
    },
    annotations: {
      xaxis: [
        {
          x: moment(props && props.activeDate).format("x"),
          strokeDashArray: 10,
          borderColor: "rgba(17,17,17,0.1)",
        },
      ],
    },

    xaxis: {
      type: "datetime",
      labels: {
        datetimeUTC: false,
      },
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
      max: maxValue,
    },
    plotOptions: {
      candlestick: {
        colors: {
          upward: "#2F66F6",
          downward: "#FF8207",
        },
      },
    },

    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

        return (
          "<ul style='padding:15px;list-style-type:none'>" +
          "<li><b>Open</b>: " +
          data.y[0] +
          "</li>" +
          "<li><b>High</b>: " +
          data.y[1] +
          "</li>" +
          "<li><b>Low</b>: " +
          data.y[2] +
          "</li>" +
          "<li><b>Close</b>: " +
          data.y[3] +
          "</li>" +
          "<li><b>Time</b>: " +
          data.x.substring(11, 20) +
          "</li>" +
          "</ul>"
        );
      },
    },
  };
  return (
    <div className="card h-420">
      {props.data && props.data.data ? (
        <div className="card-wrap">
          <div className="card-header d-flex justify-content-between">
            <div className="header-title">
              {localStorage.getItem("lastCurrencyPair")}
            </div>

            <ul className="currencies justify-content-center">
              <li>
                <button
                  className={`tag ${
                    props.activeTimeframe === "M5"
                      ? " tag-primary"
                      : "tag-light"
                  }`}
                  onClick={() => props.changeTimeframe("M5")}
                >
                  M5
                </button>
              </li>
              <li>
                <button
                  className={`tag ${
                    props.activeTimeframe === "M10"
                      ? " tag-primary"
                      : "tag-light"
                  }`}
                  onClick={() => props.changeTimeframe("M10")}
                >
                  M10
                </button>
              </li>
              <li>
                <button
                  className={`tag ${
                    props.activeTimeframe === "M15"
                      ? " tag-primary"
                      : "tag-light"
                  }`}
                  onClick={() => props.changeTimeframe("M15")}
                >
                  M15
                </button>
              </li>
              <li>
                <button
                  className={`tag ${
                    props.activeTimeframe === "M30"
                      ? " tag-primary"
                      : "tag-light"
                  }`}
                  onClick={() => props.changeTimeframe("M30")}
                >
                  M30
                </button>
              </li>
              <li>
                <button
                  className={`tag ${
                    props.activeTimeframe === "M60"
                      ? " tag-primary"
                      : "tag-light"
                  }`}
                  onClick={() => props.changeTimeframe("M60")}
                >
                  M60
                </button>
              </li>
              <li>
                <button
                  className={`tag ${
                    props.activeTimeframe === "M240"
                      ? " tag-primary"
                      : "tag-light"
                  }`}
                  onClick={() => props.changeTimeframe("M240")}
                >
                  M240
                </button>
              </li>
            </ul>
          </div>
          <div className="card-body">
            <div id="economicEvent"></div>
            <Chart
              options={options}
              series={series}
              type="candlestick"
              width={"100%"}
              height={320}
            />
            <div className="utc-label">
              Candles: 5 before and 14 after {props && props.activeDate} (GMT +
              00:00 UTC)
            </div>
          </div>
        </div>
      ) : (
        <div className="no-data">
          <span>No Data Found!</span>
        </div>
      )}
    </div>
  );
};

export default OHLC;
