import React, { useState, useEffect } from "react";
import { SwapOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import moment from "moment";
const EconomicEvent = (props) => {
  var actualArray = [];
  var forecastArray = [];
  var datesArray = [];
  if (props.data) {
    props.data.event_data.forEach((element) => {
      actualArray.push(element.actual);
      forecastArray.push(element.forecast);
      datesArray.push(
        element.date +
          " " +
          moment(element.time, "h:mm:ss A").format("HH:mm:ss")
      );
    });
  }

  const series = [
    {
      name: "Actual",
      data: actualArray,
    },
    {
      name: "Forecast",
      data: forecastArray,
    },
  ];
  const options = {
    chart: {
      type: "bar",
      id: "mychart",
      events: {
        dataPointSelection: (event, chartContext, config) => {
          const selectedDate =
            config.w.config.xaxis.categories[config.dataPointIndex];

          ApexCharts.exec(
            "mychart",
            "updateOptions",
            {
              colors: [
                function ({ value, seriesIndex, dataPointIndex, w }) {
                  if (config.dataPointIndex === dataPointIndex) {
                    return "#2BB515";
                  } else {
                    return "#2F66F6";
                  }
                },
                function ({ value, seriesIndex, dataPointIndex, w }) {
                  if (config.dataPointIndex === dataPointIndex) {
                    return "#2BB515";
                  } else {
                    return "#64B5F6";
                  }
                },
              ],
            },
            false,
            true
          );
          const lastCurrencyPair = localStorage.getItem("lastCurrencyPair");
          props.getOHLC(selectedDate, lastCurrencyPair);
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "95%",
        endingShape: "rounded",
      },
    },

    colors: [
      function ({ value, seriesIndex, dataPointIndex, w }) {
        if (props.data && props.data.event_data.length - 1 === dataPointIndex) {
          return "#2BB515";
        } else {
          return "#2F66F6";
        }
      },
      function ({ value, seriesIndex, dataPointIndex, w }) {
        if (props.data && props.data.event_data.length - 1 === dataPointIndex) {
          return "#2BB515";
        } else {
          return "#64B5F6";
        }
      },
    ],

    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    yaxis: {
      // labels: {
      //   formatter: function (value) {
      //     var val = Math.abs(value);
      //     if (val >= 1000) {
      //       val = (val / 1000).toFixed(0) + " K";
      //     }
      //     return val;
      //   },
      // },
    },
    xaxis: {
      categories: datesArray,
      // tickPlacement: "on",
      labels: {
        rotate: -45,
        rotateAlways: -45,
        style: {
          fontSize: "12px",
        },
      },
    },
    legend: {
      show: true,
      position: "top",
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      // y: {
      //   formatter: function (value) {
      //     var val = Math.abs(value);
      //     if (val >= 1000) {
      //       val = (val / 1000).toFixed(0) + " K";
      //     }
      //     return val;
      //   },
      // },
    },
  };

  return (
    <>
      <div className="card h-420">
        {props.data ? (
          <div className="card-wrap">
            <div className="card-header">
              <Row gutter={16}>
                <Col span={8}>
                  <div
                    className="header-title cursor-pointer"
                    onClick={props.showFilter}
                  >
                    {props.data.currency} | {props.data.country}
                    <SwapOutlined />
                  </div>
                </Col>
                <Col span={8}>
                  <div
                    className="header-title cursor-pointer text-center"
                    onClick={props.showFilter}
                  >
                    {props.data.economic_event} <SwapOutlined />
                  </div>
                </Col>
                <Col span={8}>
                  <div
                    className="header-title cursor-pointer text-right"
                    onClick={props.showFilter}
                  >
                    {props.data.from_date} - {props.data.to_date}
                    <SwapOutlined />
                  </div>
                </Col>
              </Row>
            </div>
            <div className="card-body">
              <div id="economicEvent">
                <Chart
                  id="chart"
                  options={options}
                  series={series}
                  type="bar"
                  width={"100%"}
                  height={310}
                />
              </div>
            </div>
            <div className="utc-label">GMT + 00:00 UTC</div>
          </div>
        ) : (
          <div className="no-data">
            <span>No Data Found!</span>
          </div>
        )}
      </div>
    </>
  );
};

export default EconomicEvent;
